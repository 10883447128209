export default {
    mode: "notebook",
    header: {
        "id": "3ccafb5d-fd27-4c9b-9862-derft",
        "updated": {
            "seconds": 1589471593,
            "nanoseconds": 768000000
        },
        "title": "רבקה כהן - ניהול תיק",
        "direction": "rtl",
        "type": "Notebook",
        "notebook": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
    },
    banner: "https://wellisco.com/wp-content/uploads/2020/08/eshel-he-top.png",
    style: {
        backgroundColor: "#14548c",
        color: "white",
    },
    cardTemplate: {
        direction: "rtl",
        lang: "he",
    },
    cards: [
        {
            "title": "שגרת תרגול טובה",
            // "body": "Patient experianced mood swings over the past month",
            "direction":"rtl",
            "noteBy": "me",
            "cardStyle": "ok",
            "type": "SimpleCard",
            "notebook": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
        },
        
        {
            "title": "שינויים במצב רוח",
            // "body": "Patient experianced mood swings over the past month",
            "direction":"rtl",
            "noteBy": "me",
            "cardStyle": "alert",
            "type": "SimpleCard",
            "notebook": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
        },


        {
            "id": "3ccafb5d-fd27-4c9b-9862-3a20d10dfdg",
            "updated": {
                "seconds": 1589471593,
                "nanoseconds": 768000000
            },
            "graph": {
                "type": "hicharts",
                "data": {
                    "title": {
                        "text": 'Progress'
                    },
                    "series": [{
                        data: [1, 2, 4, 6, 4, 6, 6.4],
                    }]
                }
            },
            "actionButtons": [
                { "text": "Join Zoom Meeting" }
            ],
            "type": "TileCard",
            "direction":"rtl",
            "bodyTitle": "שינויי מצב רוח",
            "body": "המטופל חווה תנודתיות במצב הרוח בחודש האחרון",
        },
        
        {
            // "title": "Mood Swings",
            // "body": "Write a personal message to Rivka",
            input: {
                "placeholder": "כתוב הודעה אישית לרבקה",
            },
            "noteBy": "me",
            "type": "SimpleCard",
            "direction":"rtl",
            "notebook": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
        },
        // {
        //     "id": "3ccafb5d-fd27-4c9b-9862-3a20d10481f6",
        //     "updated": {
        //         "seconds": 1589471593,
        //         "nanoseconds": 768000000
        //     },
        //     "videoId": "8NUxvJS-_0k",
        //     "created": {
        //         "nanoseconds": 560000000,
        //         "seconds": 1589470337
        //     },
        //     "bodyTitle": "Understand Your Breathing",
        //     "body": "Knowledge | Physiology | Background",
        //     "noteBy": "me",
        //     "type": "TileCard",
        //     "notebook": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
        // },
     
    ]
}