const inviteFriendMesg = encodeURIComponent("הי, ויאולט כהן מזמינה אותך להצטרף למכבי שירותי בריאות בתנאים מיוחדים! \n\nhttps://demo.wellisco.com/?nb=demo/maccabi/friend")

export default {
    mode: "notebook",
    header: {
        "id": "3ccafb5d-fd27-4c9b-9862-derft",
        "updated": {
            "seconds": 1589471593,
            "nanoseconds": 768000000
        },
        "title": "ויאולט - ראשי",
        "direction": "rtl",
        "type": "Notebook",
        "notebook": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
    },
    banner: "https://wellisco.com/wp-content/uploads/2020/08/eshel-he-top.png",
    style: {
        backgroundColor: "#14548c",
        color: "white",
    },
    cards: [

        {
            "bodyTitle": "ברוכים הבאים למכבי",
            "direction": "rtl",
            "basicImage": "https://wellisco.com/wp-content/uploads/2020/08/bring-a-friend.png",
            "body": "ויאולט כהן הזמינה אותך להצטרף למכבי שירותי בריאות ומגיע לכם תנאים מיוחדים!<br/><br/>עדכנו מספר טלפון וניצור איתכם קשר בהקדם.",
            "form": [
                { label: "טלפון", value: "" },
            ],

            "actionButtons": [
                {
                    "text": "שלח",
                    action: {
                        type: "navigate", path: `https://api.whatsapp.com/send?text=${inviteFriendMesg}`
                    }
                }
            ],
        },

    ]
}