export default [
    {
      "date": "3/4/19",
      "text": "הודעה מטעם משרד הבריאות עבור מי שנטל תכשיר אלטרוקסין בתקופה פברואר 2011 - נובמבר 2011     http://www.pages06.net/maccabihealthservices/Prod/Altoxin/Altoxin.html/"
    },
    {
      "date": "5/2/19",
      "text": "הודעה מטעם משרד הבריאות עבור מי שנטל תכשיר אלטרוקסין בתקופה פברואר 2011- נובמבר2011 https://Macb.li/1786"
    },
    {
      "date": "5/5/19",
      "text": "שלום ויאולט, שירות חדש ושימושי במיוחד עבורך! תזכורות לתורים לרופא/ת נשים. לקבלת התזכורות היכנסי ללינק, הקישי סיסמה ותחת סעיף 'תורים' בחרי באפשרות 'תזכורות לרפואת נשים'  https://Macb.li/1514?d=26325977 בברכה, מכבי שירותי בריאות"
    },
    {
      "date": "7/10/19",
      "text": "שלום ויאולט, מכבי שוב הוזילה את העלות החודשית של הביטוחים המשלימים ואתם נהנים מהכי טוב בפחות כסף. רוצים לדעת כמה אתם משלמים עכשיו? https://Macb.li/1876?d=26325977בברכה, מכבי שירותי בריאות."
    },
    {
      "date": "7/22/19",
      "text": "שלום ויאולט, האם כתובת המייל שלך מעודכנת במכבי? נשמח לוודא זאת מולך, כדי שנוכל להמשיך לתזכר אותך על תורים, לשלוח לך תוצאות בדיקות ולעמוד לשירותך בכל בקשה.  לעדכון כתובת המייל באפליקציה:             https://mc.maccabi4u.co.il/transfer/?module=updatePersonalDetails בברכה, מכבי שירותי בריאות"
    },
    {
      "date": "7/29/19",
      "text": "אפליקציית מכבי החדשה כבר כאן! נוחה יותר, מהירה יותר ומותאמת אישית. היכנסו ותראו איך הפכנו את הטוב ביותר לטוב יותר. האפליקציה זמינה בחנויות לכלל חברי מכבי-  https://Macb.li/1920?d=26325977"
    },
    {
      "date": "8/5/19",
      "text": "ויאולט שלום, לנו במכבי חשוב שתדעי יותר. אז ריכזנו עבורך מידע חשוב בנושא אנמיה https://Macb.li/1539?d=26325977בברכה, מכבי שירותי בריאות"
    },
    {
      "date": "8/20/19",
      "text": "ויאולט שלום, לנו במכבי חשוב שתדעי יותר. אז ריכזנו עבורך מידע חשוב בנושא כאב גב תחתון https://Macb.li/1641?d=26325977בברכה, מכבי שירותי בריאות"
    },
    {
      "date": "9/3/19",
      "text": "ויאולט שלום, לנו במכבי חשוב שתדעי יותר. אז ריכזנו עבורך מידע חשוב בנושא בורסיטיס https://Macb.li/1765?d=26325977בברכה, מכבי שירותי בריאות"
    },
    {
      "date": "11/11/19",
      "text": "שלום ויאולט, חושבים צעד קדימה - דואגים לבריאותך. אחד מכל שלושה בני 65 ומעלה חווה נפילה פעם בשנה לפחות. ריכזנו עבורך מדריך מקיף שיסייע לך למנוע את הנפילה הבאה:   https://Macb.li/1777?d=26325977בברכה, מכבי שירותי בריאות"
    },
    {
      "date": "11/12/19",
      "text": "שלום רב, המרכזים הרפואיים של מכבי פועלים כסדרם למעט תפרח, סעד, עין הבשור, מכון פיזיותרפיה בסניף ג אשדוד, טיפת חלב נס ציונה, מרפאת שחפת רחובות, מעבדת רמת יוסף בת ים, מכון התפתחות הילד ברחוב צאלים חולון. במידה ואין באפשרותכם להגיע לתורים, אנא בטלו אותם. בשל המצב יתכנו עיכובים /היעדרויות, נודה להבנתכם.  אנא התעדכנו בהודעות נוספות ככל שתהיינה באתר מכבי. לרשותכם קו חם להתייעצות בנושאי מתח וחרדה 3555*, שלוחה 3.. בברכת יום שקט ורגוע(NL)מכבי שירותי בריאות."
    },
    {
      "date": "11/26/19",
      "text": "שלום ויאולט,  חושבים צעד קדימה - דואגים לבריאותך. אחד מכל שלושה בני 65 ומעלה חווה נפילה פעם בשנה לפחות.  ריכזנו עבורך מדריך מקיף שיסייע לך למנוע את הנפילה הבאה:   https://Macb.li/2092?d=26325977 בברכה, מכבי שירותי בריאות"
    },
    {
      "date": "12/29/19",
      "text": "שלום ויאולט, האם הבחנת לאחרונה בשינוי כלשהו ביכולת הזיכרון או החשיבה? גם אם לא – חשוב להכיר את התופעה. לרשימת המלצות לשיפור היכולות לחצו:https://Macb.li/2232?d=26325977  בברכה , מכבי שירותי בריאות"
    },
    {
      "date": "2/4/20",
      "text": "שלום ויאולט, האם כתובת המייל שלך מעודכנת במכבי? נשמח לוודא זאת מולך, כדי שנוכל להמשיך לתזכר אותך על תורים, לשלוח לך תוצאות בדיקות ולעמוד לשירותך בכל בקשה.  לעדכון כתובת המייל >> https://Macb.li/1834?d=26325977 בברכה, מכבי שירותי בריאות"
    },
    {
      "date": "2/6/20",
      "text": "שלום ויאולט, בגיל מבוגר ויסות חום הגוף נפגע ובמזג אויר קר קיימת סכנה למכת קור (היפותרמיה). לטיפים כיצד לשמור על חום הגוף וחום הבית לחצו על הקישור  https://Macb.li/2280?d=26325977  בברכה, מכבי שירותי בריאות"
    },
    {
      "date": "2/24/20",
      "text": "שלום ויאולט, האפליקציה של מכבי הגיעה למיליון משתמשים! אם עוד לא הורדת, הגיע הזמן>>  https://Macb.li/2310?d=26325977)  בברכה, מכבי שירותי בריאות"
    },
    {
      "date": "3/13/20",
      "text": "חברים יקרים, בנסיבות המיוחדות ובהמשך להמלצת משרד הבריאות להישאר בבית במקרה של חום, צינון או שיעול, ריכזנו עבורכם את כל האפשרויות לשמור על בריאותכם מבלי לצאת מהבית :  https://Macb.li/2386?d=26325977  בברכה, מכבי שירותי בריאות"
    },
    {
      "date": "3/19/20",
      "text": "שלום ויאולט,  לאור הנחיות משרד הבריאות חלים שינויים בפעילות שירותי מכבי.חשוב להתעדכן לפני קבלת השירות. לtext >> https://bit.ly/2J2tKJm   כמו כן, אנו עומדים לרשותך בכל האמצעים הדיגיטליים מסביב לשעון כדי למנוע יציאה מהבית: https://bit.ly/2w9rn53 בברכה, מכבי שירותי בריאות"
    },
    {
      "date": "3/23/20",
      "text": "שלום ויאולט,במיוחד בימים מאתגרים אלו חשוב לנו לעדכן אותך בשינויים החלים בשירותי מכבי.אנא עדכן/י את כתובת המייל >>   https://Macb.li/1834?d=26325977   בברכה, מכבי שירותי בריאות"
    },
    {
      "date": "3/29/20",
      "text": "שלום ויאולט,  חשוב לנו לשמור על בריאותך.מהיום ניתן לקבוע תור טלפוני למרבית הרופאים והמטפלים.  לקביעת תור לחצו: https://bit.ly/39tn1TX כמו כן, מכבי מעמידה לרשותך מגוון גדול של שירותי רפואה שניתן לצרוך מבלי לצאת מהבית: https://bit.ly/39riEbD  חשוב להתעדכן בשינויים החלים בפעילות שירותי מכבי, כולל בבתי מרקחת מכבי פארם: https://bit.ly/39tn1TX   בברכת בריאות שלמה וטובה,  מכבי שירותי בריאות"
    },
    {
      "date": "4/4/20",
      "text": "שלום ויאולט, החל מיום א' 5/4 בצהרי היום, הפניות לבדיקות לגילוי קורונה יינתנו על ידי רופאי מכבי ועל בסיס קריטריונים מוגדרים. את הבדיקות ניתן יהיה לבצע גם במעבדות מכבי הייעודיות שהוכשרו ללקיחת הדגימות החל מה 6/4. הבדיקה תתבצע רק לאחר קבלת הפנייה מרופאי מכבי.    במקרה של חום, שיעול או קוצר נשימה – יש לפנות לרופא המטפל. אם הופנית לבדיקה לגילוי קורונה נא להישאר בבידוד מרגע קבלת ההפניה ועד לקבלת התוצאה. מידע נוסף : bit.ly/345k8Hy בברכה, מכבי שירותי בריאות"
    },
    {
      "date": "5/6/20",
      "text": "שלום ויאולט, חדש במכבי! צ'אט בערב עם רופאי מכבי בכל יום בין 19:00-23:00. נכנסים לאפליקציית K, מתארים את המצב הרפואי וממשיכים לצ'אט עם רופאי מכבי >>  https://Macb.li/2493?d=26325977בברכה, מכבי שירותי בריאות"
    },
    {
      "date": "5/18/20",
      "text": "גם לך קצת קשה לחזור לשגרה?לכולנו. בדיוק בשביל זה הקמנו את 'עתיד בריא' מתחם תוכן חדש עם מומחי מכבי שילווה אותך בקלות לחזרה לשגרה בריאה היכנסו ? https://Macb.li/2578?d=26325977"
    },
    {
      "date": "5/20/20",
      "text": "המרפאה סגורה? כאב ראש מתגבר?  נא להכיר: צ'אט עם רופאי מכבי כל יום בין 19:00 ל-23:00, כולל סופ''ש!(NL)נכנסים לאפליקציית K, מתארים את המצב הרפואי, וממשיכים לצ'אט >>  https://Macb.li/2513?d=26325977  בברכה, מכבי שירותי בריאות"
    },
    {
      "date": "7/20/20",
      "text": "ויאולט שלום, לשירותך מגוון רחב של שירותים רפואיים מבלי לצאת מהבית:https://Macb.li/3766?d=26325977 נזכירך שאמצעי ההגנה הטובים ביותר הם עטיית מסכה, ריחוק חברתי ושמירה על היגיינה. לתשומת ליבך – אין להגיע למרכזים הרפואיים במכבי במקרים של בידוד, מחלת הקורונה, המתנה להפניה/לבדיקת קורונה. ניתן להמיר תור קיים לתור טלפוני אם מתאפשר.  בברכת בריאות שלמה, מכבי שירותי בריאות"
    },
    {
      "date": "8/6/20",
      "text": "ויאולט,מזל טוב! בריאות הצלחה ואושר. יש לנו מתנה קטנה בשבילך - שאלון קצר שיגלה לך משהו חדש על עצמך. שנתחיל? https://Macb.li/2017?d=26325977 בברכה, מכבי שירותי בריאות."
    },
    {
      "date": "8/13/20",
      "text": "ויאולט שלום,  הוזלנו שוב את עלות הביטוח המשלים החודשית ואתם נהנים מהטוב ביותר במחיר נמוך יותר. ההוזלה תחול אוטומטית. לפרטים >>  https://Macb.li/3843?d=26325977   בברכה, מכבי שירותי בריאות"
    },
    {
      "date": "",
      "text": "בריאים יותר יחד 💗 הזמיני קרוב משפחה שיחלוק איתך מידע ועדכונים."
    },
    {
      "date": "",
      "text": "הי ויאולט, גם את הנפש חשוב לחזק. סיגל דורון מרכזת תחום סדנאות מיינדפולנס במכבי טיבעי תראה לך איך https://www.youtube.com/watch?v=omv-aSsEbPU"
    }
  ]