import _ from "lodash"

export default class BaseAgent {

    constructor(ctx) {
        this.ctx = ctx
    }

    isReady(ctx) {
        return true
    }

    isLoaded(ctx) {
        return true
    }

    allAgentsReady(...agents) {
        return _.every(agents, agent => agent.isReady())
    }

    load() {}

    create() {}
}
