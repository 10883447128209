import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const options = {
    title: {
        enabled: false,
        text: ''
    },
    plotOptions: {
        series: {
            label: {
                enabled: false
            }
        }
    },
    legend: {
        enabled: false
    },
    series: [{
        data: [1, 0, 2, 1, 1, 0, 2,1, 0, 0, 2, 0, 1,0],
        name: "love",
        label: {
                enabled: false,
        },
    }],
    chart: {
        type: "spline",
        height: "220",
        animation: {
            duration: 1000
        }
    },
    yAxis: {
        visible: false,
        min: -1,
        max: 3,
        title: {
            text: 'Temperature'
        }
    },
    xAxis: {
        visible: false,
    },
}

export default function HiChartBlock(props) {
    return <div>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    </div>
}