import _ from "lodash"
import uuid from 'uuid/v4'
import NotebookAgent from "./NotebookAgent";
import { getLocalPart } from "../vcb"

import meshService from "../services/MeSHOntologyService"

export default class MeshAgent extends NotebookAgent {

    async load() {
        let ctx = this.ctx
        let url = this.url
        let model = ctx.syncModel
        let current = model.notes[url]
        if (!current) {
            ctx.setNotes([{
                id: url,
                title: "Loading MeSH data ...",
                body: url,
                type: "Notebook",
                notebook: url,
                loading: true,
            }], "local")

            let descriptorId = getLocalPart(url)
            let notes = await meshService.getNotebook(url, descriptorId)
            ctx.setNotes(notes, "local")

            return
        }
    }
}
