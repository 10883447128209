
import React from 'react';
import _ from "lodash"

export default function CardHelper(ctx, card, info) {

    let emptyAction = () => { }
    let emptyHandler = (e) => { }

    function doAction(action) {
        if (!action) {
            console.warn("Got empty action", action)
            return
        }
        if (_.isFunction(action)) {
            return action(card,info)
        }
        if (_.isObject(action)) {
            ctx.do(action)
            return
        }

        console.warn("Bad action type", action)
    }

    let getAction = (eventType) => {
        let actionMap = _.assign({}, card.actionMap)
        let action = actionMap[eventType] || emptyAction
        return () => {
            doAction(action)
        }
    }

    let getHandler = (eventType) => {
        let actionMap = _.assign({}, card.actionMap)

        switch (eventType) {
            case "titleClick":
                return (e) => {
                    e.stopPropagation();
                    if (e.ctrlKey || e.altKey) {
                        if (actionMap.titleClick_Opt)
                            doAction(actionMap.titleClick_Opt)
                        else
                            doAction({ type: "edit", id: card.id })
                    } else
                        doAction(actionMap.titleClick)
                }
            default:
                let action = actionMap[eventType]
                if (!action)
                    return emptyHandler
                return (e) => {
                    e.stopPropagation();
                    doAction(action)
                }
        }
    }

    function getActionButton(eventType, makeComp) {
        let handler = getHandler(eventType)
        if (handler === emptyHandler)
            return undefined

        let comp = makeComp(handler)
        let compWithKey = React.cloneElement(comp, { key:  eventType })

        return compWithKey
    }

    function getActionMenu() {
        let actionMap = _.assign({}, card.actionMap)

        let actions = card.actionMap

        let items = _.map(actionMap, (action, key) => ({ text: key, action: getAction(key) }))

        return items
    }

    return { getAction, getHandler, getActionMenu, getActionButton }
}
