import _ from "lodash"
import uuid from 'uuid/v4'
import NotebookAgent from "./NotebookAgent";
import { getLocalPart } from "../vcb"

import wikiDataService from "../services/WikiDataService"

export default class WikiDataAgent extends NotebookAgent {

    getPropNote(ctx, prop) {
        let nbModel = this.getNBModel(ctx)
        if (!nbModel) return undefined

        let propNoteId = this.url + "/" + prop
        let propNote = nbModel.notes[propNoteId]
        return propNote
    }

    async load() {
        let ctx = this.ctx
        let url = this.url
        let model = ctx.syncModel
        let current = model.notes[url]
        if (!current) {
            ctx.setNotes([{
                id: url,
                title: "Loading wikidata ...",
                body: url,
                type: "Notebook",
                notebook: url,
                loading: true,
            }], "local")

            let title = _.kebabCase(getLocalPart(url))
            let notes = await wikiDataService.getNotebook(url, title)
            ctx.setNotes(notes, "local")

            return
        }
    }
}
