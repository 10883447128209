import _ from "lodash"

export function stringify(json) {
    var cache = [];
    let value = JSON.stringify(json, function (key, value) {
        if (typeof value === 'object' && value !== null) {
            if (cache.indexOf(value) !== -1) {
                // Duplicate reference found
                try {
                    // If this value does not reference a parent it can be deduped
                    return JSON.parse(JSON.stringify(value));
                } catch (error) {
                    // discard key if value cannot be deduped
                    return;
                }
            }
            // Store value in our collection
            cache.push(value);
        }
        return value;
    }, "\t");
    cache = null; // Enable garbage collection

    return value
}

export function parseJSON(str) {
    if (!str) return undefined
    let json
    try {
        json = JSON.parse(str)
    }
    catch (e) {
    }
    return json
}

export function filterKeyWords(text, query) {
    let textWords = _.words(_.toLower(text))
    let queryWords = _.words(_.toLower(query))
    let ok = _.every(queryWords, qw => _.find(textWords, tw => tw.includes(qw)))
    return ok
}

export function filter(text, query) {
    let ok = text.includes(query)
    return ok
}

export function replaceAll(str, pattern, replacement) {
    return _.join(_.split(str, pattern), replacement)
}

export function objHasText(obj, query) {
    let str = stringify(obj)
    // let hasIt = _.includes(str,_.lowerCase(txt))
    let hasIt = filter(str, query)
    return hasIt
}
