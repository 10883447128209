import _ from "lodash"
import uuid from 'uuid/v4'
import NoteToCardHelper from "./helpers/NoteToCardHelper";
import JamNotebookModel from "../jam-model/JamNotebookModel";
import BaseAgent from "./BaseAgent";
import NoteHelper from "./helpers/NoteHelper";

export default class NotebookAgent extends BaseAgent {

    constructor(ctx, url) {
        super(ctx)
        if (_.isEmpty(url))
            throw new Error("No url provided to agent")

        this.ctx = ctx
        this.url = url
        this.layout = new NoteToCardHelper(ctx)
    }

    setPivot(pivotId) {
        this.pivotId = pivotId
    }

    isReady() {
        if (!this.isLoaded()) return false
        let ctx = this.ctx
        let current = ctx.model && ctx.model.notes[this.url]
        let ok = (current && !current.loading && !current.isMissing)
        return ok
    }

    isLoaded() {
        let ctx = this.ctx
        let current = (ctx.syncModel && ctx.syncModel.notes[this.url])
        let ok = (current && !current.loading && current.listeningForNotes)
        return ok
    }

    isMissing() {
        if (!this.isLoaded() || this.isReady())
            return false
        let ctx = this.ctx
        let current = (ctx.syncModel && ctx.syncModel.notes[this.url])
        let missing = (!current || current.isMissing)
        return missing
    }

    async load() {
        let ctx = this.ctx
        let current = (ctx.syncModel && ctx.syncModel.notes[this.url])
        let helper = NoteHelper(current)

        let baseLinks = helper.getLinksByPred("@base")
        _.forEach(baseLinks, link => ctx.importNotebook(link.obj))

        ctx.do({ type: "loadNotebook", id: this.url })
    }

    // create() {
    //     alert('love')

    //     this.ctx.storage.getNotebook(this.url)

    // }

    getNBModel() {
        let ctx = this.ctx
        let url = this.url
        let model = ctx.syncModel || ctx.model
        let notebookNote = model.notes[url]
        if (!notebookNote) return undefined
        let nbModel = JamNotebookModel.getNotebookModel(model, url)
        return nbModel
    }

    getCards(what, template) {
        return this.getCardsV2(what, template)
    }

    getNotebookNote() {
        let ctx = this.ctx
        let url = this.url
        let model = ctx.model
        let notebookNote = model.notes[url]
        return notebookNote
    }

    // getAppBarCard() {
    //     let ctx = this.ctx
    //     let url = this.url
    //     let model = ctx.model
    //     let notebookNote = model.notes[url]
    //     if (!notebookNote) return { title: "" }

    //     let writeMessage = card => {
    //         console.log("writeMessage", this, card)
    //         ctx.do({ type: "setPanel", panel: "writeMessage", panelState: { notebookId: this.url, pivotId: card.id } })
    //     }

    //     let actionMap = { writeMessage }

    //     return {
    //         id: notebookNote.id,
    //         title: notebookNote.title || notebookNote.caption,
    //         direction: notebookNote.direction,
    //         actionMap
    //     }
    // }

    getCardsV2(what, template) {
        let ctx = this.ctx
        let url = this.url
        let model = ctx.model
        let notebookNote = model.notes[url]
        if (!notebookNote) return []

        // if (notebookNote.by !== ctx.userId)
        //     ctx.importNotebook(url + "@" + ctx.userId)

        let nbModel = JamNotebookModel.getNotebookModel(model, url)
        let flatNotebookNote = nbModel.notes[notebookNote.id]
        // let rdf = nbModel.rdf

        let pivotId = this.pivotId || notebookNote.id
        let pivotNote = model.notes[pivotId]
        console.log("pivotId", pivotId)

        let handleSelect = (card, info) => {
            console.log("handleSelect", this, card, info)
            // let editCtx = nbModel.getNoteEditCtx(card.id)
            // ctx.do({ type: "setPivot", pivotId: card.id })
            let newNote = this.overrideNote(card.id, { select: { selected: info.value } })
            console.log("newNote", newNote)
            ctx.setNotes([newNote], "local")
        }

        let setPivot = card => {
            console.log("setPivot", this, card)
            // let editCtx = nbModel.getNoteEditCtx(card.id)
            ctx.do({ type: "setPivot", pivotId: card.id })
        }

        let updateCard = card => {
            // console.log("setPivot", this, card)
            // // let editCtx = nbModel.getNoteEditCtx(card.id)
            // ctx.do({ type: "setPivot", pivotId: card.id })
        }

        let editCard = card => {
            console.log("editCard", this, card)
            let editCtx = nbModel.getNoteEditCtx(card.id)
            ctx.do({ type: "editCard", ...editCtx })
        }
        let writeMessage = card => {
            console.log("writeMessage", this, card)
            ctx.do({ type: "setPanel", panel: "writeMessage", panelState: { notebookId: this.url, pivotId: card.id } })
        }
        let contentInVideo = card => {
            console.log("contentInVideo", this, card, cards)

            // ctx.do({ type: "setPanel", panel: "writeMessage", panelState: { notebookId: this.url, pivotId: card.id } })
        }

        let actionMap = {
            // titleClick: setPivot, 
            // updateCard,
            titleClick_Opt: editCard,
            handleSelect,
        }
        let writeActionMap = _.assign({}, actionMap, { writeMessage })
        let contentInVideoActionMap = { titleClick: contentInVideo }
        let addNoteCards = (note, template, curActionMap) => cards.push(this.layout.getNoteCard(note, template, curActionMap || actionMap))

        if (what === "root") {
            let root = nbModel.explore(this.pivotId).note()
            if (!root)
                root = nbModel.explore().note()

            let card = this.layout.getNoteCard(root, template)
            return [card]
        }

        if (what === "appBar") {
            let card = this.layout.getNoteCard(flatNotebookNote, "self", writeActionMap)
            return [card]
        }

        var cards = []

        let mainLinks = nbModel.explore(pivotId).goodLinks()

        if (pivotId !== notebookNote.id)
            addNoteCards(pivotNote, "header", writeActionMap)

        _.forEach(mainLinks, link => {
            // if (!note.hidden)

            if (link.obj.type === "Reference") {
                let refAgent = ctx.importNotebook(link.obj.refUrl)
                let refCards = refAgent.getCards("", "ref")
                cards = _.concat(cards, refCards)
            }
            else if (link.pred === "content") {
                addNoteCards(link.obj, "self")
            }
            else if (link.pred === "item") {
                addNoteCards(link.obj, "title", writeActionMap)
                let content = nbModel.explore(link.obj.id).all("content").notes()
                _.forEach(content, note => addNoteCards(note, "self"))
            }
            else if (link.pred === "contentInVideo") {
                addNoteCards(link.obj, "title", actionMap)
                let content = nbModel.explore(link.obj.id).all("content").notes()
                _.forEach(content, note => addNoteCards(note, "self"))
            }
            else
                addNoteCards(link.obj, "title")
        })

        return cards
    }

    overrideNote(noteId, delta) {
        let notes = []
        let existingOverride = this.getOverridingNote(noteId)
        let newOverride = this.makeOverridingNote(noteId)
        let override = existingOverride || newOverride
        let newNote = _.merge({}, override, delta)
        // let newPivotLinks = _.concat([{ pred: "content", obj: newMsgNote.id }], pivotOverride.links || [])
        // let newPivotNote = _.assign({}, pivotOverride, { links: newPivotLinks })
        return newNote
    }

    getOverridingNote(baseId) {
        let ctx = this.ctx
        let model = ctx.asyncModel
        let nbModel = this.getNBModel(ctx)
        let note = nbModel.getOverridingNote(baseId)
        return note
    }

    makeOverridingNote(baseId) {
        return {
            id: uuid(),
            notebook: this.url,
            // title: "Override " + baseId + " in " + this.url,
            links: [
                { pred: "@base", obj: baseId }
            ]
        }
    }

}
