import React, { Component, useContext, Suspense } from 'react';
import _ from "lodash"

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { isMobile } from "react-device-detect";
import { BrowserRouter, Switch, Route, Redirect, useLocation, withRouter, useParams } from "react-router-dom";

import readingTime from 'reading-time';

import { MuiThemeProvider } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import { auth, firestore } from '../../firebase';
import authentication from '../../services/authentication';
import theming from '../../services/theming';

import ErrorBoundary from '../ErrorBoundary';
import LaunchScreen from '../LaunchScreen';
// import Bar from '../Bar';
// import Router from '../Router';
import DialogHost from '../DialogHost';
// import HomeContent from '../HomeContent';
import JamApp from '../../jam-app/JamApp';
import JamAppView from '../../jam-app/JamAppView';
import JamDesktopView from '../../jam-app/JamDesktopView';
import JamCtx from "../../jam-app/JamCtx"

import SignInScreen from '../SignInScreen/SignInScreen';
import JamActionQueue from '../../jam-app/JamActionQueue';
import { lightBlue } from '@material-ui/core/colors';
// import LogicPanel from '../Logic/LogicPanel';
import CardDemoPanel from '../../jam-app/panels/CardDemoPanel';
import JamAppSection from '../../jam-app/JamAppSection';

const LogicPanel = React.lazy(() => import("../Logic/LogicPanel"))

const initialState = {
  ready: false,
  performingAction: false,

  theme: theming.defaultTheme,

  user: null,
  userData: null,

  aboutDialog: {
    open: false
  },

  signUpDialog: {
    open: false
  },

  signInDialog: {
    open: false
  },

  settingsDialog: {
    open: false
  },

  deleteAccountDialog: {
    open: false
  },

  signOutDialog: {
    open: false
  },

  snackbar: {
    autoHideDuration: 0,
    message: '',
    open: false
  }
};

const styles = (theme => ({
  mainBox: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
    alignContenet: "center",
    // justifyItems: "center",
    justifyContent: "space-around",
    overflow: "hidden",
    // backgroundColor: "black",
    // margin: "10px",
  },
  desktopFrame: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    backgroundColor: "white",
  },
  mobileFrame: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    backgroundColor: "white",
  },
  adminPanel: {
    width: "100%",
    height: "100%",
    backgroundColor: "var(--page-bg)",
  },
}))

let isFirstAuth = true

class App extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.actionQueue = new JamActionQueue()
  }

  resetState = (callback) => {
    this.setState({
      ready: true,

      theme: theming.defaultTheme,

      user: null,
      userData: null
    }, callback);
  };

  setTheme = (theme, callback) => {
    if (!theme) {
      this.setState({
        theme: theming.defaultTheme
      }, callback);

      return;
    }

    this.setState({
      theme: theming.createTheme(theme)
    }, callback);
  };

  openDialog = (dialogId, callback) => {
    const dialog = this.state[dialogId];

    if (!dialog || dialog.open === undefined || null) {
      return;
    }

    dialog.open = true;

    this.setState({ dialog }, callback);
  };

  closeDialog = (dialogId, callback) => {
    const dialog = this.state[dialogId];

    if (!dialog || dialog.open === undefined || null) {
      return;
    }

    dialog.open = false;

    this.setState({ dialog }, callback);
  };

  closeAllDialogs = (callback) => {
    this.setState({
      aboutDialog: {
        open: false
      },

      signUpDialog: {
        open: false
      },

      signInDialog: {
        open: false
      },

      settingsDialog: {
        open: false
      },

      deleteAccountDialog: {
        open: false
      },

      signOutDialog: {
        open: false
      }
    }, callback);
  };

  deleteAccount = () => {
    this.setState({
      performingAction: true
    }, () => {
      authentication.deleteAccount().then(() => {
        this.closeAllDialogs(() => {
          this.openSnackbar('Deleted account');
        });
      }).catch((reason) => {
        const code = reason.code;
        const message = reason.message;

        switch (code) {
          default:
            this.openSnackbar(message);
            return;
        }
      }).finally(() => {
        this.setState({
          performingAction: false
        });
      });
    });
  };

  signOut = () => {
    this.setState({
      performingAction: true
    }, () => {
      authentication.signOut().then(() => {
        this.closeAllDialogs(() => {
          this.openSnackbar('Signed out');
        });
      }).catch((reason) => {
        const code = reason.code;
        const message = reason.message;

        switch (code) {
          default:
            this.openSnackbar(message);
            return;
        }
      }).finally(() => {
        this.setState({
          performingAction: false
        });
      });
    });
  };

  openSnackbar = (message, autoHideDuration = 2, callback) => {
    this.setState({
      snackbar: {
        autoHideDuration: readingTime(message).time * autoHideDuration,
        message,
        open: true
      }
    }, () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  };

  closeSnackbar = (clearMessage = false) => {
    const { snackbar } = this.state;

    this.setState({
      snackbar: {
        message: clearMessage ? '' : snackbar.message,
        open: false
      }
    });
  };

  render() {
    const {
      user,
      userData,
      theme,
      ready,
      performingAction,
    } = this.state;

    const {
      aboutDialog,
      signUpDialog,
      signInDialog,
      settingsDialog,
      deleteAccountDialog,
      signOutDialog
    } = this.state;

    const { snackbar } = this.state;

    let onJamEvent = event => {
      switch (event) {
        case "about":
          this.openDialog('aboutDialog')
          break
        case "sign-up":
          this.openDialog('signUpDialog')
          break
        case "sign-in":
          this.openDialog('signInDialog')
          break
        case "sign-out":
          this.openDialog('signOutDialog')
          break
        case "settings":
          this.openDialog('settingsDialog')
          break
      }
    }

    let { classes } = this.props

    if (user)
      console.log("User:", user)

    function DemoPanel(props) {
      const { project } = useParams()
      console.log("project", project)
      return <CardDemoPanel />
    }

    function JamResponsiveView(props) {
      const ctx = useContext(JamCtx)
      const curFrame = ctx.history.getCurrentFrame()
      console.log("curFrame", curFrame)
      return user ?
        (curFrame?.panel == "logic" ?
          <Suspense
            fallback={<div id="app-loading-spinner">
              <img src="/spinner.svg" />
            </div>}>
            <LogicPanel />
          </Suspense>
          :
          isMobile ?
            <div className={classes.mobileFrame} >
              <JamAppView viewId="main" />
            </div>
            :
            <div className={classes.desktopFrame} >
              <JamDesktopView viewId="main" />
            </div>
        )
        :
        <SignInScreen
          user={user}
          openSnackbar={this.openSnackbar}
        />
    }

    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <div className={classes.mainBox}>
          {/* <div className={isMobile ? classes.mobileFrame : classes.desktopFrame} > */}

          <ErrorBoundary>
            {!ready &&
              <LaunchScreen />
            }

            {ready &&
              <JamApp actionQueue={this.actionQueue} sendEventToParent={onJamEvent} >
                <BrowserRouter>
                  <Switch>
                    {/* <Route path="/" >
                      <JamResponsiveView />
                    </Route> */}
                    <Route component={JamResponsiveView} />

                    {/* <Route path="/link/">
                      {withRouter(props => {
                        let { pathname } = useLocation()
                        let ctx = useContext(JamCtx)
                        // console.log("pathname",pathname)
                        // console.log("props",props)
                        let id = pathname.substr("/link/".length)
                        ctx.do({ type: "selectNotebook", id })
                        return <Redirect to={`/`} />
                      }
                      )}
                    </Route>

                    <Route path="/logic/">
                      <div className={classes.adminPanel}>
                        <Suspense
                          fallback={<div id="app-loading-spinner">
                            <img src="/spinner.svg" />
                          </div>}>
                          <LogicPanel />
                        </Suspense>
                      </div>
                    </Route>

                    <Route path="/demo/">
                      {withRouter(props => {
                        const pathParams = useParams()
                        const { match, location, history } = props;
                        let hashParams = hashToParams(location.hash)
                        return <JamAppSection ctxOverride={{ pathParams, hashParams }}>
                          <JamResponsiveView />
                        </JamAppSection>
                      })
                      }
                    </Route> */}

                  </Switch>
                </BrowserRouter>

                <DialogHost
                  user={user}
                  dialogs={
                    {
                      aboutDialog: {
                        dialogProps: {
                          open: aboutDialog.open,

                          onClose: () => this.closeDialog('aboutDialog')
                        },

                        props: {
                          user: user
                        }
                      },

                      signUpDialog: {
                        dialogProps: {
                          open: signUpDialog.open,

                          onClose: (callback) => {
                            this.closeDialog('signUpDialog');

                            if (callback && typeof callback === 'function') {
                              callback();
                            }
                          }
                        },

                        props: {
                          performingAction: performingAction,

                          openSnackbar: this.openSnackbar
                        }
                      },

                      signInDialog: {
                        dialogProps: {
                          open: signInDialog.open,

                          onClose: (callback) => {
                            this.closeDialog('signInDialog');

                            if (callback && typeof callback === 'function') {
                              callback();
                            }
                          }
                        },

                        props: {
                          performingAction: performingAction,

                          openSnackbar: this.openSnackbar
                        }
                      },

                      settingsDialog: {
                        dialogProps: {
                          open: settingsDialog.open,

                          onClose: () => this.closeDialog('settingsDialog')
                        },

                        props: {
                          user: user,
                          userData: userData,
                          theme: theme,

                          openSnackbar: this.openSnackbar,

                          onDeleteAccountClick: () => this.openDialog('deleteAccountDialog')
                        }
                      },

                      deleteAccountDialog: {
                        dialogProps: {
                          open: deleteAccountDialog.open,

                          onClose: () => this.closeDialog('deleteAccountDialog')
                        },

                        props: {
                          performingAction: performingAction,
                          userData: userData,

                          deleteAccount: this.deleteAccount
                        }
                      },

                      signOutDialog: {
                        dialogProps: {
                          open: signOutDialog.open,

                          onClose: () => this.closeDialog('signOutDialog')
                        },

                        props: {
                          title: 'Sign out?',
                          contentText: 'While signed out you are unable to manage your profile and conduct other activities that require you to be signed in.',
                          dismissiveAction: <Button color="primary" onClick={() => this.closeDialog('signOutDialog')}>Cancel</Button>,
                          confirmingAction: <Button color="primary" disabled={performingAction} variant="contained" onClick={this.signOut}>Sign Out</Button>
                        }
                      }
                    }
                  }
                />

                <Snackbar
                  autoHideDuration={snackbar.autoHideDuration}
                  message={snackbar.message}
                  open={snackbar.open}
                  onClose={this.closeSnackbar}
                />
              </JamApp>
            }
          </ErrorBoundary>
          {/* </div> */}
        </div>
      </MuiThemeProvider>
    );
  }

  componentDidMount() {
    this.onAuthStateChangedObserver = auth.onAuthStateChanged((user) => {
      // The user is not signed in or doesn’t have a user ID.

      console.log("onAuthStateChanged", user)

      if (!user || !user.uid) {
        if (isFirstAuth) {
          isFirstAuth = false
          auth.signInAnonymously().catch(function (error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // ...
          });
          return
        }

        if (this.userDocumentSnapshotListener) {
          this.userDocumentSnapshotListener();
        }



        this.resetState();

        return;
      }

      isFirstAuth = false

      // The user is signed in, begin retrieval of external user data.
      this.userDocumentSnapshotListener = firestore.collection('users').doc(user.uid).onSnapshot((snapshot) => {
        let data = snapshot.data();

        // The user doesn’t have a data point, equivalent to not signed in.
        // if (!snapshot.exists || !data) {
        //   if (this.userDocumentSnapshotListener) {
        //     this.userDocumentSnapshotListener();
        //   }

        //   this.resetState();

        //   return;
        // }

        // The user doesn’t have a data point, use defaults.
        if (!snapshot.exists || !data) {
          data = {
            username: user.displayName || user.email || user.phoneNumber,
            theme: {
              primaryColor: "light-blue",
              secondaryColor: "light-blue",
              type: "light"
            }
          }
        }

        this.setTheme(data.theme, () => {
          this.setState({
            ready: true,

            user: user,
            userData: data
          });
          this.actionQueue.push({ type: "updateUser", user })
        });
      }, (error) => {
        this.resetState(() => {
          const code = error.code;
          const message = error.message;

          switch (code) {
            default:
              this.openSnackbar(message);
              return;
          }
        });
      });
    }, (error) => {
      this.resetState(() => {
        const code = error.code;
        const message = error.message;

        switch (code) {
          default:
            this.openSnackbar(message);
            return;
        }
      });
    });
  }

  componentWillUnmount() {
    if (this.onAuthStateChangedObserver) {
      this.onAuthStateChangedObserver();
    }

    if (this.userDocumentSnapshotListener) {
      this.userDocumentSnapshotListener();
    }
  }
}

export default withStyles(styles)(App);
