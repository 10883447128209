import React, { useContext, Component } from 'react';
import iframlyService from "../services/iframlyService"

export default function IFramely(props) {
    let url = props.url
    return (<div style={{ maxWidth: "660px", minWidth: "326px" }}>
        <div style={{ left: 0, width: "100%", height: 0, position: "relative", paddingBottom: "63%", paddingTop: "284px" }}>
            <iframe src={`https://cdn.iframe.ly/api/iframe?api_key=bd0b64d037fa00ada3609d&app=1&media=1&url=${url}`}
                style={{ border: 0, top: 0, left: 0, width: "100%", height: "100%", position: "absolute" }} allowFullScreen>
            </iframe>
        </div>
    </div>)
}
// export default class IFramely extends Component {

//     componentDidMount() {
//         // This is required if you use pure embed.js approach
//         // Or if you use lazy-loading of iFrames
//         // It initiates the iFrame and adjusts its height if required
//         window.iframely && window.iframely.load();
//     }

//     // Depending on your components, you may need to move iframely.load() 
//     // from `componentDidMount` to `componentDidUpdate`. Quote from React docs:
//     // "Do note that componentDidMount will however not be called on component updates"

//     getIframelyHtml(url) {
//         // If you use embed code from API
//         return {__html: this.iframelyEmbedHtmlCode};

//         // Alternatively, if you use plain embed.js approach without API calls:
//         // return {__html: '<a href="' + this.url + '" data-iframely-url></a>'};
//         // no title inside <a> eliminates the flick

//         // but getting actual HTML from our APIs is still recommended 
//         // as it will have better sizing initially

//         // return { __html: `<a href="${url}" data-iframely-url></a>` };

//         // return { __html:  `<div style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: 56.25%;"><iframe src="https://www.youtube.com/embed/-nu-1iIGaSQ?rel=0" style="border: 0; top: 0; left: 0; width: 100%; height: 100%; position: absolute;" allowfullscreen scrolling="no" allow="encrypted-media; accelerometer; gyroscope; picture-in-picture"></iframe></div>` }

//         // return '<div>Love</div>'
//     }

//     // https://cdn.iframe.ly/xt25YR?playerjs=1&click_to_play=true&img=1&lazy=1&v=1&app=1

//     render() {
//         // `dangerouslySetInnerHTML` is safe,
//         // Naming is meant to make sure you trust the source,
//         // with &iframe=1&omit_script=1 - the source will be always be Iframely 
//         return <div dangerouslySetInnerHTML={this.getIframelyHtml(this.props.url)} ></div>
//     }

// }