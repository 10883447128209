import _ from "lodash"

export default class SimpleRdfUtils {

    static makeRDF(notes) {

        let quads = []
        _.forEach(notes, note => {
            let nb
            let noteTriplets = []
            // quads.push({ s: note.id, o: RDF.Literal(note.title), p: "title" })
            noteTriplets.push({ s: note.id, sub: note, p: "@id", o: note.id, obj: note })
            if (note.notebook) {
                nb = note.notebook
                noteTriplets.push({ s: note.id, sub: note, p: "notebook", o: nb, obj: notes[nb] })
            }
            if (note.type) {
                let typeStr = "sj/type#" + note.type
                noteTriplets.push({ s: note.id, sub: note, p: "@type", o: typeStr, obj: { id: typeStr } })
            }
            _.forEach(note.links, link => {
                if (notes[link.obj]) {
                    if (link.pred !== "notebook") {
                        let obj = notes[link.obj]
                        noteTriplets.push({ s: note.id, sub: note, p: link.pred, o: link.obj, obj: obj })
                        // if (link.pred === "notebook")
                        //     nb = link.obj
                    }
                }
            })

            _.forEach(noteTriplets, trip => {
                quads.push(Object.assign(trip, { g: nb }))
            })
        })

        return { quads }
    }

    static match(rdf, s, p, o, g) {

        let quads = _.filter(rdf.quads, q => (
            (s ? q.s === s : true) && (p ? q.p === p : true) && (o ? q.o === o : true) && (g ? q.g === g : true)
        ))

        // console.log("Mached", { s, p, o, g }, quads)

        return quads
    }
}