import React, { Suspense, useContext } from 'react';
import _ from "lodash"

import { withStyles } from '@material-ui/core/styles';

import JamCtx from '../jam-app/JamCtx';
import JamPanelFactory from './panels/JamPanelFactory';
import Footer from './Footer';

const styles = (theme) => ({

    jamContent: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden",
        overflowX: "hidden",
        height: "100%",
        // width: 406,
        // maxWidth: 406,
        maxWidth: 375,
        width: "100%",
        left: 0,
        right: 0,
        // height: 500,
        // backgroundColor: "black",
    },

    panelContainer: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflowX: "hidden",
        overflowY: "hidden",
    },

    jamContainer: {
        flexGrow: 1,
    },

    panelFooter: {
        flexGrow: 0,
    },

    editNote: {
        flexGrow: 1,
    },
    emptyStateIcon: {
        fontSize: theme.spacing(12)
    },

    button: {
        marginTop: theme.spacing(1)
    },

    buttonIcon: {
        marginRight: theme.spacing(1)
    },

    pageContent: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        margin: "20px"
    }
});

function JamAppView(props) {

    const { classes } = props;
    const ctx = useContext(JamCtx)
    let curFrame = ctx.history.getCurrentFrame()
    const showFooter = (curFrame.panel != "demo")
    return (
        <div className={classes.jamContent}>
            {/* <TopFiller /> */}
            <div className={classes.panelContainer}>
                {(() => {
                    // let Panel = JamPanelFactory.create(curFrame.panel)
                    return <Suspense
                        fallback={<div id="app-loading-spinner">
                            <img src="/spinner.svg" />
                        </div>}
                    >
                        <JamPanelFactory key={curFrame.panel} panelId={curFrame.panel} model={ctx.model} {...curFrame} />
                    </Suspense>
                })()}
            </div>
            {showFooter && <div className={classes.panelFooter}>
                <Footer />
            </div>}
        </div>
    )
}

export default withStyles(styles)(JamAppView);
