import React, { Component } from 'react';

import PropTypes from 'prop-types';

import firebase from 'firebase';
import { auth } from '../../firebase';

import { withStyles } from '@material-ui/core/styles';

import { auth as firebaseuiAuth } from "firebaseui"

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import logoImage from "../../assets/images/triple-ying-yang-path.png"

import Button from '@material-ui/core/Button/Button';

const styles = (theme) => ({
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
    alignContenet: "center",
    justifyContent: "space-around",
  },

  logoText: {
    textAlign: "center",
    fontSize: 30,
  },
  loginLogo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  loginLogoImage: {
    margins: "auto",
    width: 180,
  },
  loginSingleButtonContainer: {
    height: 300,
    // backgroundColor: "red",
  },
  loginSingleButton: {
    // height: 300,
    // backgroundColor: "red",
    width: 200,
  },
  button: {
    margin: theme.spacing(2)
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
});

class SignInScreen extends Component {
  // signInWithEmailLink = () => {
  //   const { user } = this.props;

  //   if (user) {
  //     return;
  //   }

  //   const emailLink = window.location.href;

  //   if (!emailLink) {
  //     return;
  //   }

  //   if (auth.isSignInWithEmailLink(emailLink)) {
  //     let emailAddress = localStorage.getItem('emailAddress');

  //     if (!emailAddress) {
  //       this.props.history.push('/');

  //       return;
  //     }

  //     authentication.signInWithEmailLink(emailAddress, emailLink).then((value) => {
  //       const user = value.user;
  //       const displayName = user.displayName;
  //       const emailAddress = user.email;

  //       this.props.openSnackbar(`Signed in as ${displayName || emailAddress}`);
  //     }).catch((reason) => {
  //       const code = reason.code;
  //       const message = reason.message;

  //       switch (code) {
  //         case 'auth/expired-action-code':
  //         case 'auth/invalid-email':
  //         case 'auth/user-disabled':
  //           this.props.openSnackbar(message);
  //           break;

  //         default:
  //           this.props.openSnackbar(message);
  //           return;
  //       }
  //     }).finally(() => {
  //       this.props.history.push('/');
  //     });
  //   }
  // };

  render() {
    // Styling
    const { classes } = this.props;

    const uiConfig = {
      // Popup signin flow rather than redirect flow.
      signInFlow: 'popup',
      // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
      signInSuccessUrl: '/',
      // We will display Google and Facebook as auth providers.
      signInOptions: [
        firebaseuiAuth.AnonymousAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      ]
    };

    firebase.auth.languageCode = "iw"

    return (
      <div className={classes.loginContainer}>
        <div className={classes.loginLogo}>
          <img src={logoImage} className={classes.loginLogoImage} />
          {/* <div className={classes.logoText}>
            Physiojam.com
            </div> */}
        </div>
        <div className={classes.loginFirebaseUI}>
          <StyledFirebaseAuth className="panel-fixed-cell" uiConfig={uiConfig} firebaseAuth={auth} />
        </div>
        {/* <div className={classes.loginSingleButtonContainer}>
          <Button variant="contained" size="large" color="primary" className={classes.loginSingleButton}
            onClick={e => this.doAnonymousSignIn()}
          >
            כניסה
            </Button>
        </div> */}
      </div>
    )
  }

  componentDidMount() {
    // this.signInWithEmailLink();
  }

  doAnonymousSignIn() {
    auth.signInAnonymously().catch(function (error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // ...
    });

  }
}

SignInScreen.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Properties
  user: PropTypes.object
};

// export default withRouter(withStyles(styles)(HomeContent));
export default withStyles(styles)(SignInScreen)
