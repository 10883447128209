import _ from "lodash"
import { Graph, alg } from "graphlib"

export default class GraphLibUtils {

    static makeGraph(notes) {
        var g = new Graph({ multigraph: true });

        _.forEach(notes, note => {
            g.setNode(note.id)
            _.forEach(note.links, link => {
                if (notes[link.obj])
                    g.setEdge(note.id, link.obj, link.pred);
            })
        })

        return g
    }

    static orderByPivot(graph,pivotId) {

        let distanceMap = alg.dijkstra(graph,pivotId)

        let distanceArray = _.map(distanceMap,(entry,key) => ({ id: key, distance: entry.distance }))

        let sorted = _.sortBy(distanceArray,"distance")

        let list = _.map(sorted,entry => entry.id)

        return list
    }

}
