import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from "lodash"

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import JamCtx from '../jam-app/JamCtx';
import CardHelper from "./CardHelper"

import { Content, Media, Header } from "./ContentBlocks"

const useStyles = makeStyles(theme => ({
  card: {
    // maxWidth: 345,
    marginBottom: "10px",
    border: "1px solid #CCC",

  },
  card_ok: {
    // maxWidth: 345,
    backgroundColor: "#DDFFDD",
    marginBottom: "10px",
    border: "none",
  },
  card_alert: {
    // maxWidth: 345,
    backgroundColor: "#FFDDDD",
    marginBottom: "10px",
    border: "none",
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  // actionIcon: {
  //   color: theme.palette.primary.light
  // },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: theme.palette.primary[100],
  },
  actionButton: {
    color: theme.palette.grey[100]
  }
}));

export default function SimpleCard(props) {
  const jamCtx = useContext(JamCtx);
  const classes = useStyles();
  const card = props.data
  const data = card

  let helper = CardHelper(jamCtx, card)

  const cardClasses = {
    "ok": classes.card_ok,
    "alert": classes.card_alert
  }
  let cardClass = cardClasses[data.cardStyle] || classes.card
  return (
    <Card key={card.id} className={["SimpleCard", cardClass, data.direction == "rtl" ? "rtl" : ""].join(" ")}
      onClick={helper.getHandler("titleClick")}
      raised={false}
    >
      <Header key="headre" data={data} helper={helper} />

      <Media key="media" data={data} helper={helper} />
      <Content key="content" data={data} helper={helper} />

      {/* </CardActionArea> */}

      {/* {(!_.isEmpty(data.actions)) && (<CardActions>
        {_.map(data.actions, (action, key) =>
          <Button key={key} size="small" color="primary">
            {action.text}
          </Button>
        )}
      </CardActions>)
      } */}

      {/*       
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Method:</Typography>
          <Typography paragraph>
            Heat 1/2 cup of the broth in a pot until simmering, add saffron and set aside for 10
            minutes.
          </Typography>
          <Typography paragraph>
            Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over medium-high
            heat. Add chicken, shrimp and chorizo, and cook, stirring occasionally until lightly
            browned, 6 to 8 minutes. Transfer shrimp to a large plate and set aside, leaving chicken
            and chorizo in the pan. Add pimentón, bay leaves, garlic, tomatoes, onion, salt and
            pepper, and cook, stirring often until thickened and fragrant, about 10 minutes. Add
            saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.
          </Typography>
          <Typography paragraph>
            Add rice and stir very gently to distribute. Top with artichokes and peppers, and cook
            without stirring, until most of the liquid is absorbed, 15 to 18 minutes. Reduce heat to
            medium-low, add reserved shrimp and mussels, tucking them down into the rice, and cook
            again without stirring, until mussels have opened and rice is just tender, 5 to 7
            minutes more. (Discard any mussels that don’t open.)
          </Typography>
          <Typography>
            Set aside off of the heat to let rest for 10 minutes, and then serve.
          </Typography>
        </CardContent>
      </Collapse> */}
    </Card>
  );
}