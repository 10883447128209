import _ from "lodash"

import FirebaseStorage from "./FirebaseStorage"
import MemStorage from "./MemStorage"
import debounce from "debounce"

export default function Storage() {
    const storage = {}

    const firebaseStorage = FirebaseStorage()
    const memStorage = MemStorage()

    const stores = {
        firebaseStorage,
        memStorage
    }
    
    storage.handlers = []
    storage.firebaseNotes = {}
    storage.memNotes = {}

    storage.setNotes = (notes, storeName) => {
        switch (storeName) {
            case "local":
                memStorage.setNotes(notes)
                break
            default:
                firebaseStorage.setNotes(notes)
        }
    }

    storage.mergeNotes = (notes, storeName) => {
        switch (storeName) {
            case "local":
                memStorage.mergeNotes(notes)
                break
            default:
                firebaseStorage.mergeNotes(notes)
        }
    }

    storage.deleteNote = (note, storeName) => {
        switch (storeName) {
            case "local":
                memStorage.deleteNote(note)
                break
            case "firebase":
                firebaseStorage.deleteNote(note)
                break
            default:
        }
    }

    storage.isListening = (notebookId) => {
    }
    
    storage.getNotebook = (notebookId) => {
        return firebaseStorage.getNotebook(notebookId)
    }

    storage.listen = (notesHandler) => {

        storage.handlers.push(notesHandler)

        storage.notifyHandlers()
    }

    storage.notifyHandlers = debounce(() => {
        var notes = _.merge({}, storage.firebaseNotes, storage.memNotes)
        _.forEach(storage.handlers, handler => handler(notes))
    }, 100)

    storage.init = () => {
        firebaseStorage.listen(notes => {
            storage.firebaseNotes = notes
            storage.notifyHandlers()
        })

        memStorage.listen(notes => {
            storage.memNotes = notes
            storage.notifyHandlers()
        })
    }

    storage.init()

    return storage
}