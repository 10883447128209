import _ from "lodash"
import { vcb, getLabel } from "../../vcb";

import NotebookAgent from "../NotebookAgent";
import WikipediaAgent from "../WikipediaAgent";
import WikiWtfAgent from "../WikiWtfAgent";
import YouTubeAgent from "../YouTubeAgent";
import WikiDataAgent from "../WikiDataAgent";
import MeshAgent from "../MeshAgent";

const agents = {
    base: { agent: NotebookAgent },
    wiki: { agent: WikipediaAgent },
    wikiWtf: { agent: WikiWtfAgent },
    wikiYouTube: { agent: YouTubeAgent },
    wikiData: { agent: WikiDataAgent },
    mesh: { agent: MeshAgent },
}

function getAgent(ctx, url) {
    let entry
    let rdfPrefix = getLabel(url)
    if (rdfPrefix)
        entry = agents[rdfPrefix]
    if (!entry)
        entry = agents["base"]

    let agent = new (entry.agent)(ctx, url)

    return agent
}

export default class AgentFactory {

    getAgent(ctx, url) {
        let agent = getAgent(ctx, url)

        if (!agent.isLoaded())
            agent.load()

        return agent
    }

}

// var service = new AgentFactory()
// export default service