import _ from "lodash"
import uuid from 'uuid/v4'
import wikiService from "../services/WikiServices"
import NotebookAgent from "./NotebookAgent";
import JamNotebookModel from "../jam-model/JamNotebookModel";
import NoteToCardHelper from "./helpers/NoteToCardHelper";
import { getLocalPart } from "../vcb"

export default class WikiWtfAgent extends NotebookAgent {

    getCards(what, template) {
        let ctx = this.ctx
        let nbModel = JamNotebookModel.getNotebookModel(ctx.model,this.url)

        let wtfFirst = nbModel.explore().first("main").first("item").note()
        let wtfOthers = _.tail(nbModel.explore().first("main").all("item").notes())

        switch (what) {
            case "firstSection":
                return this.layout.getNoteCard(wtfFirst,template)
            case "sections":
                return this.layout.getNotesCards(wtfOthers,template)
        }
        console.error("Could not get for ", this.url, what, template)

        return []
    }

    async load() {
        let ctx = this.ctx
        let url = this.url
        let model = ctx.syncModel
        let current = model.notes[url]
        if (!current) {
            ctx.setNotes([{
                id: url,
                title: "Loading ... love",
                body: url,
                type: "Notebook",
                notebook: url,
                loading: true,
            }], "local")

            let title = getLocalPart(url)
            let notes = await wikiService.getWikiWtfNotebook(url, title)
            ctx.setNotes(notes, "local")

            return
        }
    }
}
