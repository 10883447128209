import _ from "lodash"
import RDF from "./SimpleRdfUtils"

export default class ModelExplorer {
    constructor(model, baseId) {
        this.model = model
        this.cur = baseId ? model.idMap[baseId] : model.notebookId
    }

    first(pred) {
        let cur = this.cur
        if (!cur)
            return this

        let rdf = this.model.rdf
        let quads = RDF.match(rdf, this.cur, pred)
        let first = _.first(quads)
        if (first)
            this.cur = first.o
        else
            this.cur = undefined

        return this
    }

    all(pred) {
        let cur = this.cur
        if (!cur)
            return this

        let rdf = this.model.rdf
        let quads = RDF.match(rdf, this.cur, pred)
        this.cur = _.map(quads, q => q.o)

        return this
    }

    id() {
        if (_.isArray(this.cur)) return undefined
        return this.cur
    }

    ids() {
        if (!_.isArray(this.cur)) return []
        return this.cur
    }

    note() {
        if (_.isArray(this.cur)) return undefined
        return this.model.notes[this.cur]
    }

    notes() {
        if (!_.isArray(this.cur)) return []
        return _.map(this.cur, id => this.model.notes[id])
    }

    links() {
        let note = this.model.notes[this.cur]
        if (!note) return []
        
        let links = _.map(note.links, link => ({ pred: link.pred, obj: this.model.notes[link.obj] }))
        return links
    }

    goodLinks() {
        let links = this.links()
        let goodLinks = _.filter(links, link => !_.isUndefined(link.obj))
        return goodLinks
    }
}