const inviteFriend = encodeURIComponent("הי, ויאולט כהן מזמינה אותך להצטרף למכבי שירותי בריאות בתנאים מיוחדים! \n\nhttps://demo.wellisco.com/?nb=demo/maccabi/friend")
const inviteToMove = encodeURIComponent("הי, ויאולט כהן מזמינה אותך לתרגול שיווי משקל ומניעת נפילות! \n\nhttps://demo.wellisco.com/?nb=demo/maccabi/move")

export default {
    mode: "notebook",
    header: {
        "id": "3ccafb5d-fd27-4c9b-9862-derft",
        "updated": {
            "seconds": 1589471593,
            "nanoseconds": 768000000
        },
        "title": "ויאולט - ראשי",
        "direction": "rtl",
        "type": "Notebook",
        "notebook": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
    },
    banner: "https://wellisco.com/wp-content/uploads/2020/08/eshel-he-top.png",
    style: {
        backgroundColor: "#14548c",
        color: "white",
    },
    cards: [
        // {
        //     "id": "3ccafb5d-fd27-4c9b-9862-3a20d10481f6",
        //     "updated": {
        //         "seconds": 1589471593,
        //         "nanoseconds": 768000000
        //     },
        //     "videoId": "8NUxvJS-_0k",
        //     "created": {
        //         "nanoseconds": 560000000,
        //         "seconds": 1589470337
        //     },
        //     "bodyTitle": "Understand Your Breathing",
        //     "body": "Knowledge | Physiology | Background",
        //     "noteBy": "me",
        //     "type": "TileCard",
        //     "notebook": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
        // },
        // {
        //     title: "love 3"
        // },
        {
            "direction": "rtl",
            "body": "הי ויאולט, ברוכה הבאה 🌼<br/> זו המחברת האישית שלך. כאן תוכלי למצוא תמיד הכוונה וידע ולעקוב אחרי הבריאות שלך.",
            "type": "MessageCard",
            "noteBy": "me",

        },

        {
            // body: "שלום רב, קיימת הודעה חשובה בעניין תרופה אותה הנך נוטל/ת. המידע המלא באתר מכבי.",
            direction: "rtl",
            basicImage: "https://wellisco.com/wp-content/uploads/2020/08/in-box.png",
            link: "https://www.pages06.net/maccabihealthservices/Electoxin",
            body: "ריכוז הודעות אחרונות שנשלחו לטלפון או למייל",

            "list": [
                {
                    "text": "שלום רב, קיימת הודעה חשובה בעניין תרופה אותה הנך נוטל/ת. המידע המלא באתר מכבי.",
                    action: { type: "setPanel", panel: "demo", pathParts: ["maccabi", "electoxin"] }
                }
            ],

            "actionButtons": [
                {
                    "text": "לרשימה מלאה",
                    action: {
                        type: "setPanel", panel: "demo", pathParts: ["maccabi", "inbox"]
                    }
                }
            ],

        },


        {
            "basicImage": "https://wellisco.com/wp-content/uploads/2020/08/סכרת-טיזר.jpg",
            "direction": "rtl",
            "type": "SimpleCard",
            "bodyTitle": "לחיות עם הסוכרת",
            "body": "הכננו עבורך עלון מידע אודות סוכרת ושיגרת החיים איתה",
            "actionButtons": [
                {
                    "text": "לעלון המידע",
                    action: {
                        type: "setPanel", panel: "demo", pathParts: ["maccabi", "diabetes"]
                    }
                }
            ],
        },

        {
            "basicImage": "https://wellisco.com/wp-content/uploads/2020/08/50.png",
            "direction": "rtl",
            "type": "SimpleCard",
            // "bodyTitle": "לחיות עם הסוכרת",
            "body": "ריכזנו עבורך את הבדיקות שבגילך חשוב לעשות בהקדם",
            "actionButtons": [
                {
                    "text": "לפרטים",
                    action: {
                        type: "setPanel", panel: "demo", pathParts: ["maccabi", "50"]
                    }
                }
            ],
        },

        {
            "bodyTitle": "עדכון פרטים",
            "direction": "rtl",
            "basicImage": "https://wellisco.com/wp-content/uploads/2020/09/update-details-1.png",
            "body": "5 שאלות שיעזרו לנו לעזור לך",
            "actionButtons": [
                {
                    "text": "עדכני עכשיו",
                    action: {
                        type: "setPanel", panel: "demo", pathParts: ["maccabi", "details"]
                    }

                },
            ],
        },

        {
            "bodyTitle": "חבר מביא חבר",
            "direction": "rtl",
            "basicImage": "https://wellisco.com/wp-content/uploads/2020/08/bring-a-friend.png",
            "body": "הזמינו עכשיו בן/ת משפחה או חבר/ה להצטרף למכבי'",
            "actionButtons": [
                {
                    "text": "הזמיני עכשיו",
                    action: {
                        type: "navigate", path: `https://api.whatsapp.com/send?text=${inviteFriend}`
                    }
                }
            ],
        },

        {
            "id": "550bafbb-71f9-442b-8ff5-0a98780d9895",
            "noteBy": "me",
            "body": "תרגול שיווי משקל ומניעת נפילות",
            "updated": {
                "seconds": 1588268246,
                "nanoseconds": 508000000
            },
            "notebook": "079bf8cd-a387-4688-98c3-538126032e24",
            "type": "Video",
            "created": {
                "nanoseconds": 78000000,
                "seconds": 1586690971
            },
            "direction": "rtl",
            "xxbody": "איך היה?",

            "videoId": "mlZbPYUzfUg",
            "bodyTitle": "קצת לזוז",
            // "select": {
            //     "options": [
            //         {
            //             "value": "good",
            //             "text": "היה יופי"
            //         },
            //         {
            //             "value": "mid",
            //             "text": "קצת קשה"
            //         },
            //     ],
            //     "caption": "איך היה?"
            // },
            "actionButtons": [
                { "text": "היה כיף, שתפי עם חברים",
                action: {
                    type: "navigate", path: `https://api.whatsapp.com/send?text=${inviteToMove}`
                }
            }
            ],

        },

        // {
        //     "id": "3ccafb5d-fd27-4c9b-9862-3a20d1048eft",
        //     "updated": {
        //         "seconds": 1589471593,
        //         "nanoseconds": 768000000
        //     },
        //     // "videoUrl":"https://wellisco.com/wp-content/uploads/2020/08/משחק-כדור-בסלון-חתוך.mp4",
        //     "videoId": "FANJPy3WDC4",
        //     // "basicImage": "https://wellisco.com/wp-content/uploads/2020/08/share-love-2.png",
        //     "imageClass": "image_basic",
        //     "direction": "rtl",
        //     "type": "SimpleCard",
        //     "body": "בריאים יותר יחד 💗 הזמיני קרוב משפחה שיחלוק איתך מידע ועדכונים.",
        //     // "body": "Jul 22, 2020 09:00 AM",
        //     "actionButtons": [
        //         { "text": "הזמיני עכשיו" }
        //     ],
        // },

        {
            "body": "תרגיל מיינדפולנס למבוגרים",
            "type": "Video",
            "direction": "rtl",
            "videoId": "omv-aSsEbPU",
            // "title":"תרגיל מיינדפולנס למבוגרים",
        },


        {
            "id": "3ccafb5d-fd27-4c9b-9862-xfgt",
            "updated": {
                "seconds": 1589471593,
                "nanoseconds": 768000000
            },
            // "videoId": "mclzWkiOeJU",
            "basicImage": "https://blog.maccabi4u.co.il/wp-content/uploads/2017/05/%D7%AA%D7%96%D7%95%D7%A0%D7%94-%D7%9C%D7%97%D7%A5-%D7%93%D7%9D-%D7%A8%D7%90%D7%A9%D7%99-300x206.jpg",
            "body": "האם ישנה דרך להקטין את הסיכוי לסבול מלחץ דם גבוה בעזרת תזונה?",
            "bodyTitle": "תזונה להפחתת לחץ דם",

            "direction": "rtl",

            "links": [
                {
                    "pred": "item",
                    "obj": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
                }
            ],
            "created": {
                "nanoseconds": 560000000,
                "seconds": 1589470337
            },
            // "title": "לחץ דם ובריאות",
            // "body": "Knowledge | Measurement",
            "type": "SimpleCard",
            "notebook": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
        },



        // {
        //     "id": "cfd1f4f0-4a33-4840-af7e-428cae4a5acc",
        //     "created": {
        //         "nanoseconds": 517000000,
        //         "seconds": 1586693561
        //     },
        //     // "direction": "rtl",
        //     "body": "Please call me, I have a question, Thanks",
        //     "updated": {
        //         "seconds": 1588268412,
        //         "nanoseconds": 50000000
        //     },
        //     "noteBy": "other",
        //     "links": [
        //         {
        //             "obj": "079bf8cd-a387-4688-98c3-538126032e24",
        //             "pred": "item"
        //         }
        //     ],
        //     "notebook": "079bf8cd-a387-4688-98c3-538126032e24",
        //     "type": "MessageCard"
        // },
        // {
        //     "id": "cfd1f4f0-4a33-4840-af7e-428cae4a5acc",
        //     "created": {
        //         "seconds": 1586693561,
        //         "nanoseconds": 517000000
        //     },
        //     "body": "Hi Danny, Please note that we moved the time of the weekly zoom meeting. Have a great day!",
        //     "links": [
        //         {
        //             "pred": "item",
        //             "obj": "079bf8cd-a387-4688-98c3-538126032e24"
        //         }
        //     ],
        //     "type": "MessageCard",
        //     "noteBy": "other",
        //     // "direction": "rtl",
        //     "notebook": "079bf8cd-a387-4688-98c3-538126032e24",
        //     "updated": {
        //         "seconds": 1588268412,
        //         "nanoseconds": 50000000
        //     }
        // },

        // {
        //     "id": "3ccafb5d-fd27-4c9b-9862-xfgt",
        //     "updated": {
        //         "seconds": 1589471593,
        //         "nanoseconds": 768000000
        //     },
        //     "videoId": "MRMA4JT_VzU",
        //     "direction": "rtl",

        //     "links": [
        //         {
        //             "pred": "item",
        //             "obj": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
        //         }
        //     ],
        //     "created": {
        //         "nanoseconds": 560000000,
        //         "seconds": 1589470337
        //     },
        //     "bodyTitle": "תרקדי, קיץ בחוץ ☀️",
        //     // "body": "Knowledge | Measurement",
        //     "type": "SimpleCard",
        //     "notebook": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
        // },
    ]
}