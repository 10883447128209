import React, { useContext, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from "lodash"
import JamCtx from '../jam-app/JamCtx';
import CardHelper from './CardHelper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { getActionButtons } from "./ContentBlocks"

const useStyles = makeStyles(theme => ({
  card: {
    // maxWidth: 345,
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
  },
  title: {
    flexGrow: 1
  }
}));

export default function TitleCard(props) {
  const jamCtx = useContext(JamCtx);
  const classes = useStyles();
  const card = props.data
  const data = card

  let helper = CardHelper(jamCtx,card)

  const actionButtons = getActionButtons(helper,classes)
  let cardClass = "TitleCard " + classes.card
  if (data.direction == "rtl") cardClass += " rtl"

  return (
    <Box m={1} className={cardClass}
      onClick={helper.getHandler("titleClick")}
    >
      <Typography className={classes.title} variant="h6">{data.title}</Typography>
      {/* <Header key="media" data={data} helper={helper} /> */}
      <>{actionButtons}</>

    </Box>
  )
}