import inbox from "./macabbi_violet_messages"

export default {
    mode: "notebook",
    header: {
        "id": "3ccafb5d-fd27-4c9b-9862-derft",
        "updated": {
            "seconds": 1589471593,
            "nanoseconds": 768000000
        },
        "title": "ויאולט - ראשי",
        "direction": "rtl",
        "type": "Notebook",
        "notebook": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
    },
    banner: "https://wellisco.com/wp-content/uploads/2020/08/eshel-he-top.png",
    style: {
        backgroundColor: "#14548c",
        color: "white",
    },
    cards: [

        {
            // body: "שלום רב, קיימת הודעה חשובה בעניין תרופה אותה הנך נוטל/ת. המידע המלא באתר מכבי.",
            direction: "rtl",
            basicImage: "https://wellisco.com/wp-content/uploads/2020/08/in-box.png",
            body: "ריכוז הודעות אחרונות שנשלחו לטלפון או למייל",
            "list": inbox,
        },

    ]
}