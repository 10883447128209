import _ from "lodash"
import uuid from 'uuid/v4'

const useExitWarning = false

if (useExitWarning) {
    window.addEventListener("beforeunload", function (e) {
        (e || window.event).returnValue = null;
        return null;
    });
}

export default function JamAppHistoryV3(app) {

    window.onpopstate = function (event) {
        // alert(`location: ${document.location}, state: ${JSON.stringify(event.state)}`)
        let state = event.state || getInitialNavStack()[0]
        app.setState({ curNavFrame: state })
    }

    function getInitialNavStack() {
        let id = uuid()
        return [
            { id, panel: "notebook-selector" },
        ]
    }

    function back(returnAction) {
        window.history.back()
    }

    function doPushState(state) {
        let path = "/?nb=" + state.panel
        if (state.pathParts) {
            let pathEnd = _.join(state.pathParts,"/")
            path += "/" + pathEnd
        }
        else if (state?.panelState?.notebook)
            path += "/" + state.panelState.notebook

        window.history.pushState(state, "nb", path)
        app.setState({ curNavFrame: state })
    }

    function setPanelState(stateDelta) {
        let curFrame = getCurrentFrame()
        let newPanelState = _.assign({}, curFrame.panelState, stateDelta)
        let newFrame = _.assign({}, curFrame, { panelState: newPanelState })
        doPushState(newFrame)
    }

    function pushStateChange(stateDelta) {
        let curFrame = getCurrentFrame()
        let newPanelState = _.assign({}, curFrame.panelState, stateDelta.panelState)
        let newFrame = _.assign({}, curFrame, stateDelta, { panelState: newPanelState })
        doPushState(newFrame)
    }

    function getPanelState() {
        let curFrame = getCurrentFrame()
        return curFrame && curFrame.panelState
    }

    function getCurrentFrame() {
        console.log("history", window.location, window.history,urlParams)

        const urlParams = new URLSearchParams(window.location.search)
        const nbPath = urlParams.get('nb')
        let path = _.split(nbPath, "/")
        let panel = path[0]
        let notebook = path[1]
        let state = app.state.curNavFrame || window.history.state || getInitialNavStack()[0]
        state.path = path
        if (panel) state.panel = panel
        if (notebook) _.merge(state, { panelState: { notebook } })
        console.log("state", state)
        return state

    }

    return { getInitialNavStack, getCurrentFrame, getPanelState, setPanelState, pushStateChange, back, }
}
