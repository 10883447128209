import _ from "lodash"

export const vcb = {
    wiki: "https://en.wikipedia.org/wiki/",
    wikiWtf: "https://semjam.com/wiki-wtf/",
    wikiData: "https://www.wikidata.org/wiki/",
    mesh: "http://id.nlm.nih.gov/mesh/",
    wikiYouTube: "https://semjam.com/wiki-youtube-search/",
}

export function makeUrl(label,localPart) {
    let urlPrefix = vcb[label]
    if (!urlPrefix) return undefined
    let url = urlPrefix + localPart
    return url
}

export function getLocalPart(url) {
    let nsUrl = _.find(vcb, (prefixUrl, label) => url.startsWith(prefixUrl))
    if (!nsUrl) return undefined
    let tail = url.substring(nsUrl.length)
    return tail
}

export function getLabel(url) {
    let label = _.findKey(vcb, (prefixUrl, label) => url.startsWith(prefixUrl))
    return label
}

// export function getNamespacePrefix(url) {
//     let prefix = _.find(vcb, (prefix, key) => url.startsWith(prefix))
//     return prefix
// }

// export function getNamespacePrefix(url) {
//     let prefix = _.find(vcb, (prefix, key) => url.startsWith(prefix))
//     return prefix
// }

// export function getNamespaceUrl(url) {
//     let nsUrl = _.findValue(vcb, (prefix, key) => url.startsWith(prefix))
//     return nsUrl
// }

// export function removeNamespaceUrl(url) {
//     let nsUrl = getNamespaceUrl(url)
//     if (!nsUrl) return undefined
//     let tail = url.substring(nsUrl.length)
//     return tail
// }

export default vcb 