import _ from "lodash"
// import wdk from "wikidata-sdk"
import axiosJsonpPro from 'axios-jsonp-pro';
import axios from 'axios';
import { vcb, getLocalPart } from "../vcb"
import { stringify } from "../utils/StringUtils";

// Humerus A02.835.232.087.090.400

export class MeSHOntologyService {

    async getNotebook(url) {
        let descriptorId = getLocalPart(url)
        let descriptor = await this.getDescriptorData(descriptorId)
        console.log("mesh",descriptor)

        let notes = [
            {
                id: url,
                title: "MeSH " + descriptorId,
                notebook: url,
                type: "Notebook",
                body: stringify(descriptor.data)
            },
        ]

        return notes
    }

    async getDescriptorData(descriptorId) {
        if (!descriptorId) return undefined
        let url = "https://meshb.nlm.nih.gov/api/record/ui/" + descriptorId
        let loadData = url => axios.get(url, { timeout: 10000, })
            .catch(function (error) { console.error("Error loading", url, error); })
        let result = await loadData(url)
        return result
    }

    async getTreeData(treeNumber) {

        // let url = "https://www.wikidata.org/w/api.php?action=wbgetentities&titles=" + wikipediaTitle + "&sites=enwiki&format=json"
        let childrenUrl = "https://meshb.nlm.nih.gov/api/tree/children/" + treeNumber
        let parentsUrl = "https://meshb.nlm.nih.gov/api/tree/parents/" + treeNumber
        let siblingsUrl = "https://meshb.nlm.nih.gov/api/tree/children/" + _.join(_.dropRight(_.split(treeNumber, ".")), ".")

        // return axiosJsonpPro.jsonp(childrenUrl, {
        let loadData = url => axios.get(url, { timeout: 10000, })
            .catch(function (error) { console.error("Error loading", url, error); })

        let [children, parents, siblings] = await Promise.all([loadData(childrenUrl), loadData(parentsUrl), loadData(siblingsUrl)])

        return { children, parents, siblings }
    }

}

var service = new MeSHOntologyService()
export default service