export default {
    mode: "notebook",
    header: {
        "id": "3ccafb5d-faassdd27-4c9dddb-9862-derft",
        "updated": {
            "seconds": 1589471593,
            "nanoseconds": 768000000
        },
        "title": "רבקה כהן - סיעוד",
        "direction": "rtl",
        "type": "Notebook",
        "notebook": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
    },
    banner: "https://wellisco.com/wp-content/uploads/2020/08/eshel-he-top.png",
    style: {
        backgroundColor: "#14548c",
        color: "white",
    },
    cards: [
        // {
        //     "id": "3ccafb5d-fd27-4c9b-9862-3a20d10481f6",
        //     "updated": {
        //         "seconds": 1589471593,
        //         "nanoseconds": 768000000
        //     },
        //     "videoId": "8NUxvJS-_0k",
        //     "created": {
        //         "nanoseconds": 560000000,
        //         "seconds": 1589470337
        //     },
        //     "bodyTitle": "Understand Your Breathing",
        //     "body": "Knowledge | Physiology | Background",
        //     "noteBy": "me",
        //     "type": "TileCard",
        //     "notebook": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
        // },
        {
            "id": "cfd1f4f0-4a33-4840-af7e-428cae4a5acc",
            "created": {
                "nanoseconds": 517000000,
                "seconds": 1586693561
            },
            // "direction": "rtl",
            "body": "Welcome to Eshel, This notebook will help you support Rivka and monitor her health.",
            "updated": {
                "seconds": 1588268412,
                "nanoseconds": 50000000
            },
            "noteBy": "me",
            "links": [
                {
                    "obj": "079bf8cd-a387-4688-98c3-538126032e24",
                    "pred": "item"
                }
            ],
            "notebook": "079bf8cd-a387-4688-98c3-538126032e24",
            "type": "MessageCard"
        },
        {
        //     "id": "d2f38ab5-5866-4a0a-8f00-0039bd84f5e2",
        //     "type": "Question",
        //     "noteBy": "other",
        //     "updated": {
        //         "seconds": 1587905449,
        //         "nanoseconds": 148000000
        //     },
        //     "notebook": "079bf8cd-a387-4688-98c3-538126032e24",
        //     "overrideHints": [
        //         "daily"
        //     ],
        //     "direction": "rtl",
        //     "links": [
        //         {
        //             "pred": "item",
        //             "obj": "67ce0a1e-b9b8-41e2-a28d-83f552c593db"
        //         }
        //     ],
        //     "select": {
        //         "options": [
        //             {
        //                 "value": "good",
        //                 "text": "טוב"
        //             },
        //             {
        //                 "value": "mid",
        //                 "text": "בינוני"
        //             },
        //             {
        //                 "value": "bad",
        //                 "text": "לא משהו"
        //             }
        //         ],
        //         "caption": "איך את מרגישה?"
        //     },
        //     "created": {
        //         "seconds": 1586991845,
        //         "nanoseconds": 736000000
        //     }
        // },
        // {
            "id": "3ccafb5d-fd27-4c9b-9862-xfgt",
            "updated": {
                "seconds": 1589471593,
                "nanoseconds": 768000000
            },
            "videoId": "Ovrl-Q_0FgY",
            // "direction": "rtl",
            "links": [
                {
                    "pred": "item",
                    "obj": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
                }
            ],
            "created": {
                "nanoseconds": 560000000,
                "seconds": 1589470337
            },
            "bodyTitle": "Getting out of a chair",
            // "body": "Knowledge | Measurement",
            "type": "TileCard",
            "notebook": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
        },
    //    {
    //         "id": "3ccafb5d-fd27-4c9b-9862-3a20d1048eft",
    //         "updated": {
    //             "seconds": 1589471593,
    //             "nanoseconds": 768000000
    //         },
    //         "basicImage": "https://wellisco.com/wp-content/uploads/2020/08/share-love-2.png",
    //         "imageClass": "image_basic",
    //         "direction": "rtl",
    //         "type": "SimpleCard",
    //         "body": "שתפי את מחברת הטיפול עם אהוביך כדי שיעזרו ויתמכו בבריאותך",
    //         // "body": "Jul 22, 2020 09:00 AM",
    //         "actionButtons": [
    //             { "text": "שתפי עכשיו" }
    //         ],
    //     },

    //     {
    //         "id": "550bafbb-71f9-442b-8ff5-0a98780d9895",
    //         "noteBy": "me",
    //         "caption": "תרגול בישיבה",
    //         "updated": {
    //             "seconds": 1588268246,
    //             "nanoseconds": 508000000
    //         },
    //         "notebook": "079bf8cd-a387-4688-98c3-538126032e24",
    //         "type": "Video",
    //         "created": {
    //             "nanoseconds": 78000000,
    //             "seconds": 1586690971
    //         },
    //         "direction": "rtl",
    //         "xxbody": "איך היה?",
    //         "links": [
    //             {
    //                 "obj": "0ecbb5d3-dd54-4acc-aa51-b17701d5f063",
    //                 "pred": "contentInVideo"
    //             },
    //             {
    //                 "obj": "137f0441-8a16-4d78-acde-fd9fe9637f2a",
    //                 "pred": "contentInVideo"
    //             }
    //         ],
    //         "videoId": "c_0K2QuX_Yk",
    //         "title":"קצת לזוז"
    //     },
        {
            "id": "d2f38ab5-5866-4a0a-8fsds00-0039bd84f5e2",
            "type": "Question",
            "noteBy": "other",
            "updated": {
                "seconds": 1587905449,
                "nanoseconds": 148000000
            },
            "notebook": "079bf8cd-a387-4688-98c3-538126032e24",
            "select": {
                "options": [
                    {
                        "value": "good",
                        "text": "OK"
                    },
                    {
                        "value": "mid",
                        "text": "So, SO"
                    },
                ],
                "caption": "How did Rivka sleep?"
            },
            "created": {
                "seconds": 1586991845,
                "nanoseconds": 736000000
            }
        },

    ]
}