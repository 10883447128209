export default {
    mode: "notebook",
    header: {
        "id": "3ccafb5d-fd27-4c9b-9862-derft",
        "updated": {
            "seconds": 1589471593,
            "nanoseconds": 768000000
        },
        "title": "ויאולט - ראשי",
        "direction": "rtl",
        "type": "Notebook",
        "notebook": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
    },
    banner: "https://wellisco.com/wp-content/uploads/2020/08/eshel-he-top.png",
    style: {
        backgroundColor: "#14548c",
        color: "white",
    },
    cards: [

        {
            // "body": "עדכון פרטים",
            "direction": "rtl",
            "basicImage": "https://wellisco.com/wp-content/uploads/2020/09/update-details-1.png",
            // "body": "5 שאלות שיעזרו לנו לעזור לך",
            "actionButtons": [
                { "text": "עדכני עכשיו" }
            ],
            "form": [
                { label: "שם פרטי בעיברית", value: "ויאולט" },
                { label: "שם משפחה בעיברית", value: "כהן" },
                { label: "מייל", value: "violetcohen726@gmail.com" },
                { label: "נייד", value: "050-5644788" },
                { label: "כתובת מגורים", value: "רב אשי 16 תל אביב" },
                { label: "שם פרטי באנגלית", value: "Violet" },
                { label: "שם משפחה באנגלית", value: "Cohen" },
            ],
            "select": {
                "options": [
                    {
                        "value": "good",
                        "text": "עברית"
                    },
                    {
                        "value": "mid",
                        "text": "רוסית"
                    },
                    {
                        "value": "mid",
                        "text": "ערבית"
                    },
                ],
                "caption": "שפה מועדפת לתקשורת"
            },
        },
    ]
}