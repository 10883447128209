export default {
    mode: "notebook",
    header: {
        "id": "3ccafb5d-fd27-4c9b-9862-sfdt",
        "updated": {
            "seconds": 1589471593,
            "nanoseconds": 768000000
        },
        "title": "רבקה כהן - משפחה",
        "direction": "rtl",
        "type": "Notebook",
        "notebook": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
    },
    banner: "https://wellisco.com/wp-content/uploads/2020/08/eshel-he-top.png",
    style: {
        backgroundColor: "#14548c",
        color: "white",
    },
    cardTemplate: {
        direction: "rtl",
        lang: "he",
    },
    cards: [
        {
            // "title": "Mood Swings",
            // "body": "Write a personal message to Rivka",
            input: {
                "placeholder": "שלחי ברכה אישית",
            },
            "noteBy": "me",
            "cardStyle": "ok",
            "title": "אמא דיווחה על תרגול מוצלח",
            "type": "SimpleCard",
            "direction": "rtl",
            "notebook": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
        },
        {
            // "title": "Mood Swings",
            // "body": "Write a personal message to Rivka",
            input: {
                "placeholder": "שלחי המלצה אישית",
            },
            "noteBy": "me",
            // "cardStyle": "ok",
            "videoId": "dBqTVpX6AAE",
            "body": "תזונה בגיל השלישי",
            "type": "SimpleCard",
            "direction": "rtl",
            "notebook": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
        },
        {
            "title": "שינויי מצב רוח",
            "body": "היו לאמא שינויי מצב רוח בחודש האחרון",
            "direction":"rtl",
            "noteBy": "me",
            "cardStyle": "alert",
            "type": "SimpleCard",
            "notebook": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
        },
        // {
        //     "id": "3ccafb5d-fd27-4c9b-9862-3a20d10481f6",
        //     "updated": {
        //         "seconds": 1589471593,
        //         "nanoseconds": 768000000
        //     },
        //     "videoId": "8NUxvJS-_0k",
        //     "created": {
        //         "nanoseconds": 560000000,
        //         "seconds": 1589470337
        //     },
        //     "bodyTitle": "Understand Your Breathing",
        //     "body": "Knowledge | Physiology | Background",
        //     "noteBy": "me",
        //     "type": "TileCard",
        //     "notebook": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
        // },

    ]
}