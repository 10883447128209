import firebase, { firestore, Query } from "../firebase"
import _ from "lodash"
import uuid from 'uuid/v4'
import assert from "../utils/assert"

export default function FirebaseStorage() {

    const storage = {}
    const notebookListeners = {}
    storage.handlers = []
    storage.notes = {}

    const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp()

    // storage.set = (note) => {
    //     let id = note.id
    //     assert(id)
    //     let serverTimestamp = firebase.firestore.FieldValue.serverTimestamp()

    //     let dbNote = Object.assign({ created: serverTimestamp }, note, { updated: serverTimestamp })

    //     firestore.collection("notes").doc(id).set(dbNote)
    // }

    storage.setNotes = (notes) => {
        let db = firestore
        let batch = db.batch();

        let noteMap = _.keyBy(notes, "id")
        storage.notes = _.assign(storage.notes, noteMap)

        _.forEach(notes, note => {
            let dbNote = Object.assign({ created: serverTimestamp }, note, { updated: serverTimestamp })
            firestore.collection("notes").doc(note.id).set(dbNote)
        })

        batch.commit()

        _.forEach(storage.handlers, handler => handler(storage.notes))
    }

    storage.listen = (notesHandler) => {
        storage.handlers.push(notesHandler)
        firestore.collection("notes")
            // .where("type", "==", "Notebook")
            .orderBy("updated", "desc")
            .onSnapshot(function (snap) {
                let notes = _.map(snap.docs, (doc) => {
                    let note = Object.assign({ id: doc.id }, doc.data())
                    // console.log("Current note: ", note);
                    return note
                })
                let noteMap = _.keyBy(notes, 'id');
                storage.notes = _.assign(storage.notes, noteMap)

                notesHandler(noteMap)
            });
    }

    storage.deleteNote = (note) => {
        let db = firestore

        db.collection("notes").doc(note.id).delete().then(function () {
            console.log("Document successfully deleted!");
        }).catch(function (error) {
            console.error("Error removing document: ", error);
        });
    }

    storage.getNotebook = (notebookId) => {
        if (notebookId in notebookListeners)
            return

        let unsubscribeNotes = firestore.collection("notes")
            .where("notebook", "==", notebookId)
            .orderBy("updated", "desc")
            .onSnapshot(function (snap) {
                let notes = _.map(snap.docs, (doc) => {
                    let note = Object.assign({ id: doc.id }, doc.data())
                    // console.log("Current note: ", note);
                    return note
                })

                let noteMap = _.keyBy(notes, 'id');
                if (noteMap[notebookId])
                    storage.notes = _.assign({}, storage.notes, noteMap)
                else
                    storage.notes = _.assign({}, storage.notes, { [notebookId]: { id: notebookId, isMissing: true } })

                storage.notifyHandlers()
            });

        notebookListeners[notebookId] = { unsubscribeNotes }
    }

    storage.notifyHandlers = () => {
        _.forEach(storage.handlers, handler => handler(storage.notes))
    }

    // storage.setNotebook = (notebookName) => {
    //     let db = firestore
    //     let batch = db.batch();

    //     db.collection("notes").get().then(function (querySnapshot) {
    //         querySnapshot.forEach(function (doc) {
    //             var ref = db.collection("notes").doc(doc.id);

    //             return ref.update({
    //                 notebook: notebookName
    //             });
    //         });
    //     });

    //     batch.commit()
    // }

    // storage.notebookLinkToProp = () => {
    //     let db = firestore
    //     let batch = db.batch();

    //     db.collection("notes").get().then(function (querySnapshot) {
    //         querySnapshot.forEach(function (doc) {
    //             var ref = db.collection("notes").doc(doc.id);
    //             var note = doc.data()

    //             _.forEach(note.links, link => {
    //                 if (link.pred === "notebook") {
    //                     console.log("Link: " + link.pred + " " + link.obj)

    //                     ref.update({
    //                         notebook: link.obj
    //                     });

    //                     console.log("Updated")

    //                 }
    //             })
    //         });
    //     });

    //     batch.commit()
    // }

    return storage
}