import React, { Suspense } from 'react';

const NotebookPanel = React.lazy(() => import("./NotebookPanel"))
const NotebookSelectorPanel = React.lazy(() => import("./NotebookSelectorPanel"))
const AllCardsPanel = React.lazy(() => import("./AllCardsPanel"))
const EditNotePanel = React.lazy(() => import("./EditNotePanel"))
const SearchPanel = React.lazy(() => import("./SearchPanel"))
const WriteMessagePanel = React.lazy(() => import("./WriteMessagePanel"))
// const TerminalPanel = React.lazy(() => import("./TerminalPanel"))
const VideoPanel = React.lazy(() => import("./VideoPanel"))
const ExportNotesPanel = React.lazy(() => import("./ExportNotesPanel"))
const CardDemoPanel = React.lazy(() => import("./CardDemoPanel"))
// const GraphPanel = React.lazy(() => import("./GraphPanel"))

// import NotebookPanel from "./NotebookPanel"
// import NotebookSelectorPanel from "./NotebookSelectorPanel"
// import AllCardsPanel from "./AllCardsPanel"
// import EditNotePanel from "./EditNotePanel"
// import SearchPanel from "./SearchPanel"
// import WriteMessagePanel from "./WriteMessagePanel"
// import TerminalPanel from "./TerminalPanel"
// import VideoPanel from "./VideoPanel"

export default function JamPanelFactory(props) {

    let comp
    switch (props.panelId) {
        case "notebook": comp =  <NotebookPanel key={props.panelId} {...props} />; break;
        case "card-list": comp =  <AllCardsPanel key={props.panelId} {...props} />; break;
        case "notebook-selector": comp =  <NotebookSelectorPanel key={props.panelId} {...props} />; break;
        case "edit-note": comp =  <EditNotePanel key={props.panelId} {...props} />; break;
        // case "choose-target": comp =  <AllCardsPanel key={props.panelId} {...props} />; break;
        case "search": comp =  <SearchPanel key={props.panelId} {...props} />; break;
        case "wikipedia": comp =  <NotebookPanel key={props.panelId} {...props} />; break;
        // case "terminal": comp =  <TerminalPanel key={props.panelId} {...props} />; break;
        case "writeMessage": comp =  <WriteMessagePanel key={props.panelId} {...props} />; break;
        case "video": comp =  <VideoPanel key={props.panelId} {...props} />; break;
        case "export": comp =  <ExportNotesPanel key={props.panelId} {...props} />; break;
        // case "graph": comp =  <GraphPanel key={props.panelId} {...props} />; break;
        case "demo": comp = <CardDemoPanel key={props.panelId} {...props} />; break;
    }

    if (comp) {
        return comp
    }
        
    return <div>No Panel</div>
}