import React, { useContext } from 'react';
import _ from "lodash"
import JamCtx from '../jam-app/JamCtx';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Menu, MenuItem } from '@material-ui/core';
import CardHelper from './CardHelper';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    // marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  titleDetails: {
    fontSize: 8,
  }
}));

export default function AppBarCard(props) {
  const classes = useStyles();
  const jamCtx = useContext(JamCtx);
  const card = props.data || { title: "" }

  let helper = CardHelper(jamCtx, card)
  let direction = card.direction === "rtl" ? "rtl" : ""

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  let actionMenuItems = helper.getActionMenu()

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ direction }}>
        <Toolbar variant="dense" className="AppBarCard" >
          <Typography variant="h6" className={classes.title}
            onClick={helper.getHandler("titleClick")}
          >
            {card.title}
            {/* <Typography className={classes.titleDetails} >
              {"title details"}
            </Typography> */}
          </Typography>

          {!_.isEmpty(actionMenuItems) && (<>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
              onClick={handleMenuOpen}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleMenuClose}
            >
              {_.map(actionMenuItems, (item, i) => <MenuItem key={i} onClick={() => {
                handleMenuClose()
                item.action()
              }}>{item.text}</MenuItem>)}
            </Menu>
          </>)
          }
        </Toolbar>
      </AppBar>
    </div >
  );
}