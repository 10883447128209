import _ from "lodash"
import uuid from 'uuid/v4'

function copyProps(obj, ...props) {
    let flatProps = _.concat(...props)

    let copy = {}
    _.forEach(flatProps, prop => {
        let fromPropName = prop.from || prop
        let toPropName = prop.to || prop
        let value = prop.value || obj[fromPropName]
        if (value)
            copy[toPropName] = value
    })
    // console.log("copyProps", copy, obj, flatProps)
    return copy
}

let test = copyProps({
    a: "love",
    b: ["peace"],
    c: { test: "good" },
    d: "music"
}, "a", ["b", "d"], "e", { from: "d", to: "love" })

let baseProps = ["id", { from: "id", to: "noteId" }, "direction"]

export default class NoteToCardHelper {

    constructor(ctx) {
        this.ctx = ctx

        this.baseActionMap = {

            playVideo: card => {
                console.log("playVideo", this, card)
                ctx.do({ type: "setPanel", panel: "video", panelState: { videoId: card.videoId } })
            }
        
        }
        
    }

    getNotesCards(notes, mode, actionMap) {
        return _.map(notes, note => this.getNoteCard(note, mode, actionMap))
    }

    getNoteCard(note, mode, inActionMap) {
        if (!note)
            return ({ id: uuid(), type: "SimpleCard", isMissing: true, title: "Note not found" })
        
        let card
        let actionMap = _.assign({},this.baseActionMap,inActionMap)
        switch (mode) {
            case "appBar": {
                let card = copyProps(note, baseProps, "title" )
                card.title = note.title || note.caption || note.body 
                break
            }
            case "titleAndImage":
                card = note.image ?
                    copyProps(note, baseProps, "image", { from: "title", to: "bodyTitle" })
                    : copyProps(note, baseProps, "image", "title")
                break
            case "title":
                card = copyProps(note, baseProps, "title", "direction", { to: "type", value: "title" })
                card.title = note.title || note.id
                break
            case "mediaOnly":
                card = copyProps(note, baseProps, "image", "videoId")
                break
            default:
                card = Object.assign({}, note, { type: "SimpleCard", note })
        }

        if (note.type === "Message") {
            card.type = "MessageCard"
            card.noteBy = note.noteBy
            card.snapToBottom = (mode === "snapToBottom")
        }

        if (note.type === "Question") {
            card.type = "QuestionCard"
        }

        _.assign(card, { actionMap })

        return card
    }

}

// export default new NoteToCardHelper()