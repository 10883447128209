import { Subject, BehaviorSubject, ReplaySubject } from 'rxjs'

class JamActionQueue {
    
    subject = new ReplaySubject()
    constructor() {
        this.push = this.push.bind(this)
        this.addListener = this.addListener.bind(this)
    }

    push(action) {
        this.subject.next(action)
    }

    addListener(listener) {
        return this.subject.subscribe({
            next: listener
        });
    }

    unsubscribe(subscription) {
        subscription.unsubscribe()
    }
}

export default JamActionQueue