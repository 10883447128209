import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from "lodash"
import uuid from 'uuid/v4'

import Box from '@material-ui/core/Box';
// import TextField from '@material-ui/core/TextField';
// import Scrollbar from 'react-smooth-scrollbar';

import JamCtx from "../JamCtx"
// import JamContainer from "../feed/JamContainer"
import JamCardView from "../feed/JamCardView"
// import AllCardAgent from "../../agents/AllCardAgent"
// import { objHasText } from '../../utils/StringUtils';

import AppBarCard from '../../jam-card-ui/AppBarCard';
import NoteToCardHelper from '../../agents/helpers/NoteToCardHelper';

// import nefd1 from "../../demo/nefd-1"
// import eshel1 from "../../demo/eshel-1"
// import eshel_nursing from "../../demo/eshel-nursing"
// import eshel_physio from "../../demo/eshel-physio"
// import eshel_care_taker from "../../demo/eshel-care-taker"
// import maccabi_main from "../../demo/maccabi-main"

import maccabi_proj from "../../demo/maccabi_proj"

import skin from "../../demo/skins/maccabi-skin"
import ContainerDimensions from 'react-container-dimensions';

const projects = { maccabi: maccabi_proj }

// const demos = { maccabi_main, eshel_care_taker, eshel_physio, eshel_nursing, }

const useStyles = makeStyles(theme => ({
    box: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
    },
    mainCell: {
        margin: 8,
    },
    cards: {
        flexGrow: 1
    },
    staticImage: {
        width: "100%",
    }
}));

export default function CardDemoPanel(props) {
    const ctx = useContext(JamCtx)
    const classes = useStyles();
    const layoutHelper = new NoteToCardHelper(ctx)

    const frame = ctx.history.getCurrentFrame()
    // const panelState = props.panelState
    console.log("demo ctx", ctx, frame)

    let projectId = frame.path[1]
    let project = projectId && projects[projectId]

    let entryId = frame.path[2]
    let entry = (entryId && project?.entries[entryId]) || (project.defaultEntry && project?.entries[project.defaultEntry])

    // let mode = panelState?.mode
    // let demoId = panelState?.demoId
    // let demo = demoId && demos[demoId]

    // let projectId = ctx?.hashParams?.project
    // let notebookId = ctx?.hashParams?.notebook
    // let project = projectId && projects[projectId]
    // let demoId = project && notebookId && project?.notebooks[notebookId]
    // let demo = demoId && demos[demoId]

    console.log("demo", project, entry)

    if (entry) {
        if (entry.mode == "image") {
            return <div p={0} className="panel-cell-container" style={{ pisition: "relative" }}>
                <ContainerDimensions>
                    {({ width, height }) =>
                        <>
                            <img src={entry.imageUrl} className={classes.staticImage} />
                            {_.map(entry.clickZones, zone => {
                                const percent = x => x * (width / entry.width)
                                return <div style={{
                                    position: "absolute",
                                    left: percent(zone.x),
                                    top: percent(zone.y),
                                    width: percent(zone.w),
                                    height: percent(zone.h),
                                    // backgroundColor: "#F005"
                                    cursor: "pointer",
                                }}
                                    onClick={() => ctx.do(zone.action)}
                                />
                            })}
                        </>}
                </ContainerDimensions>
            </div>
        }
        if (entry.mode == "iframe") {
            return <iframe className={classes.iframe} src={entry.url} frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen/>
        }
        else if (entry.mode == "notebook") {
            let demo = entry
            let cards = _.map(demo.cards, card => _.assign({}, card, { id: uuid() }))
            let style = _.merge({}, skin.style)
            return <div p={0} className="panel-cell-container" style={style}>
                {/* <div className="panel-fixed-cell">
            <TextField key="text-field"
                // id="standard-name-xx"
                label="Filter"
                className={classes.textField}
                value={filterText}
                autoComplete="off"
                onChange={e => setFilterText(e.target.value)}
                margin="normal"
                fullWidth
            />
        </div> */}
                {demo.banner && <div key="appBar" className="panel-fixed-cell">
                    <img src={skin.banner} style={{ width: "100%" }} />
                </div>
                }
                <div p={0} className={"panel-cell-container " + classes.mainCell} style={style}>
                    <div className="panel-main-cell">
                        <JamCardView cards={cards} />
                    </div>
                </div>
            </div>

        }
        else
            return <div>No Page</div>
    }
    else {
        const notebooks = _.pickBy(project?.entries, e => (e.mode == "notebook"))
        let headerCards = _.map(notebooks, (demo, key) => {
            const note = demo?.header || { title: key, type: "Notebook" }
            const action = () => {
                ctx.do({ type: "setPanel", panel: "demo", pathParts: [projectId, key], panelState: { demoId: key } })
            }
            let card = layoutHelper.getNoteCard(note, "title", { titleClick: action })
            card.id = uuid()
            return card
        })
        return <Box p={0} className="panel-cell-container" >
            <div key="appBar" className="panel-fixed-cell">
                <AppBarCard data={{ title: "מחברות בריאות לדוגמה", direction: "rtl" }} />
            </div>
            <div className="panel-main-cell">
                <JamCardView cards={headerCards} />
            </div>
        </Box>
    }
}