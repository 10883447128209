import nefd1 from "./nefd-1"
import eshel1 from "./eshel-1"
import eshel_nursing from "./eshel-nursing"
import eshel_physio from "./eshel-physio"
import eshel_care_taker from "./eshel-care-taker"
import maccabi_main from "./maccabi-main-1"
import inbox from "./maccabi-inbox"
import friend from "./maccabi-friend"
import move from "./maccabi-move"

import updateDetails from "./maccabi-update-details"

 export default {
    skin: {
        header: {
            "id": "3ccafb5d-fd27-4c9b-9862-derft",
            "updated": {
                "seconds": 1589471593,
                "nanoseconds": 768000000
            },
            "title": "משגב - מטופלת",
            "direction": "rtl",
            "type": "Notebook",
            "notebook": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
        },
        // banner: "https://wellisco.com/wp-content/uploads/2020/08/eshel-he-top.png",
        banner: "https://wellisco.com/wp-content/uploads/2020/08/maccabi-app-header.png",
        style: {
            backgroundColor: "white",
            color: "white",
        },
    },
    personas: {
        eva: {

        }
    },
    defaultEntry: "front",
    entries: {
        "main": maccabi_main,
        "family": eshel_care_taker,
        "pro": eshel_physio,
        "helper": eshel_nursing,
        "inbox": inbox,
        "details": updateDetails,
        "friend": friend,
        "move": move,
        "memography": {
            mode: "image",
            imageUrl: "https://wellisco.com/wp-content/uploads/2020/08/mammography-appointement.jpeg",
        },
       "diabetes": {
            mode: "image",
            imageUrl: "https://wellisco.com/wp-content/uploads/2020/08/סכרת-1.jpg",
        },
       "50": {
            mode: "image",
            imageUrl: "https://wellisco.com/wp-content/uploads/2020/08/50-1.png",
        },
        "front": {
            mode: "image",
            imageUrl: "https://wellisco.com/wp-content/uploads/2020/08/maccabi-front-1.jpg",
            width: 750,
            clickZones: [
                {
                    x: 58, y: 234, w: 186, h: 192,
                    action: { type: "setPanel", panel: "demo", pathParts: ["maccabi", "main"] }
                },
                {
                    x: 590, y: 1488, w: 130, h: 72,
                    action: { type: "setPanel", panel: "demo", pathParts: ["maccabi"] }
                }
            ]
        },
        "electoxin": {
            "mode": "image",
            "imageUrl": "https://wellisco.com/wp-content/uploads/2020/08/electoxin.jpg"
        }
    }
}