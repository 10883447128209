// import axios from 'axios';
import axiosJsonpPro from 'axios-jsonp-pro';
import _ from "lodash"
import WTF from "wtf_wikipedia"
import uuid from 'uuid/v4'

var source = "https://en.wikipedia.org/w/api.php"
// var source = "https://www.physio-pedia.com/api.php"
// var source = "https://aboutus.com/api.php"
// var source = "https://www.wikidata.org/w/api.php"

var deepFind = (root, prop) => {
    if (_.isObject(root)) {
        if (root.hasOwnProperty(prop))
            return root[prop]

        for (var key in root) {
            let res = deepFind(root[key], prop)
            if (res)
                return res
        }
    }
    if (_.isArray(root)) {
        for (let i = 0; i < root.length; i++) {
            let res = deepFind(root[i], prop)
            if (res)
                return res
        }
    }
    return undefined
}

export class WikiServices {

    apiRoot = 'http://localhost:4000';

    async getWikiSearchNotes(searchTerm) {
        let results = await this.wikiSearch(searchTerm)
        console.log("Wiki search results", results);

        let noteList = (results && results.query)  ? _.map(results.query.search, result => ({
            id: "wikipedia" + result.title,
            title: result.title,
            url: "https://en.wikipedia.org/wiki/" + _.join(_.split(result.title, " "), "_"),
            source: "Wikipedia",
            body: result.snippet,
            type: "Ref"
        })) : []

        let notes = _.keyBy(noteList, 'id');

        return notes
    }

    wikiSearch(searchTerm) {
        console.log("Term:", searchTerm)
        return axiosJsonpPro.jsonp(source, {
            // url: source,
            timeout: 1000,
            params: {
                action: "query",
                list: "search",
                format: "json",
                prop: "info",
                inprop: "url",
                srsearch: searchTerm
            }
        })
            .catch(function (error) {
                console.error("An error", error);
            });
    }

    async getWikiWtfNotebook(url, title) {
        // let WTF = await import("wtf_wikipedia").then(module => module.default)
        // title = "No love"
        console.log("WTF",title,WTF,WTF.fetch)
        let wtf = await WTF.fetch(title,"en")
        let json = wtf.toJSON()
        console.log("WIkipedia wtf result", json)

        let titleText = json.title
        let sections = json.sections
        let firstSection = sections[0]
        let otherSections = _.tail(sections)
        let mainId = uuid()

        var sectionTree = []
        var last
        function processSection(sec) {
            if (sec.depth === 0) {
                last = { sec, kids: [] }
                sectionTree.push(last)
                return
            }

            // Move up to the right  parent depth
            while (last.sec.depth >= sec.depth) { last = last.parent }

            let cur = { sec, parent: last, kids: [] }
            last.kids.push(cur)

            last = cur
        }

        _.forEach(sections, sec => processSection(sec))
        console.log("wtf tree", sectionTree)

        let notebookUrl = url
        let notes = {}
        if (firstSection) {

            let firstSectionNotes = [
                {
                    id: notebookUrl,
                    title: titleText,
                    sourceLogo: "logos/wikipedia_logo.svg",
                    body: _.join(_.map([_.head(firstSection.paragraphs)], para =>
                        ("<div class='p'>" + _.join(_.map(para.sentences, sentence => sentence.text, "")) + "</div>")
                    ), ""),
                    bodyMore: _.join(_.map(_.tail(firstSection.paragraphs), para =>
                        ("<div class='p'>" + _.join(_.map(para.sentences, sentence => sentence.text, "")) + "</div>")
                    ), ""),
                    links: [
                        { obj: mainId, pred: "main" }
                    ],
                    image: _.get(_.first(firstSection.images), "thumb"),
                    notebook: notebookUrl,
                    type: "Notebook",
                    listeningForNotes: true,
                },
            ]

            function getSectionSubTitles(node) {
                let titles = []
                _.forEach(node.kids, kid => {
                    let kidTitles = getSectionSubTitles(kid)
                    titles = _.concat(titles, kidTitles)
                })
                return titles
            }

            let otherSectionsNotes = _.map(sectionTree, node => {
                let sec = node.sec
                let body = (_.isEmpty(sec.paragraphs)) ?
                    _.join(getSectionSubTitles(node), ", ")
                    :
                    _.join(_.map(sec.paragraphs, para =>
                        ("<div class='p'>" + _.join(_.map(para.sentences, sentence => sentence.text, "")) + "</div>")
                    ), "")
                return ({
                    id: uuid(),
                    title: sec.title,
                    notebook: notebookUrl,
                    body: body,
                    image: _.get(_.first(sec.images), "thumb"),
                    // links: [
                    //     { obj: notebookUrl, pred: "notebook" }
                    // ],
                    type: "Section"
                })
            })

            let mainNotes = [
                {
                    id: mainId,
                    title: titleText + "(Sec)",
                    links: _.map([...firstSectionNotes, ...otherSectionsNotes], note => ({ pred: "item", obj: note.id })),
                    notebook: notebookUrl,
                }
            ]

            notes = _.keyBy(_.concat(firstSectionNotes, mainNotes, otherSectionsNotes), 'id');
        }


        return notes
    }

    // async getWikiWTF(title) {
    //     let WTF = await import("wtf_wikipedia").then(module => module.default)
    //     return WTF.fetch(title)
    // }

    getWtfLinks(wtf) {
        let links = wtf.links().map((link) => ({ section: "-", page: link.page }))
        let categories = wtf.categories().map((cat) => ({ section: "Categories", page: "Category:" + cat }))
        let title = wtf.title().toLowerCase()
        links = categories.concat(links)
        if (title.startsWith("category:"))
            links.unshift({ section: "Category main page", page: title.substring(9) })
        return links
    }

    getWtfItem(wtf) {
        let image = _.first(wtf.images())
        let imageLink = image ? image.url() : undefined
        let section = _.first(wtf.sections())
        let sectionText = section ? section.plaintext() : undefined
        return {
            id: "sj:wiki#" + wtf.title(),
            type: "JamSub",
            title: wtf.title(),
            values: {
                image: imageLink,
                body: sectionText
            }
        }
    }

    getCategories(title) {
        console.log("Term:", title)
        return axiosJsonpPro.jsonp({
            url: source,
            timeout: 1000,
            dataType: 'jsonp',
            params: {
                action: "query",
                titles: title,
                prop: "categories",
                clshow: "!hidden",
                format: "json",
            }
        })
            .then((res) => {
                console.log("Wiki Categories", res)
                let cats = deepFind(res, "categories")
                if (cats)
                    cats = cats.map((v) => { return v.title })
                return cats
            })
            .catch(function (error) {
                console.error("An error", error);
            });
    }

    getSubCategories(title) {
        console.log("Getting sub categories for:", title)
        return axiosJsonpPro.jsonp({
            url: 'source',
            timeout: 1000,
            dataType: 'jsonp',
            params: {
                action: "query",
                cmtitle: title,
                cmtype: "subcat",
                list: "categorymembers",
                format: "json",
            }
        })
            .then((res) => {
                console.log("Sub Categories", res)
                let cats = deepFind(res, "categorymembers")
                if (cats)
                    cats = cats.map((v) => { return v.title })
                return cats
            })
        // .catch(function (error) {
        //     console.error("An error", error);
        // });
    }

    getAllSubCategories(baseCat) {

        return new Promise((resolve, reject) => {
            var catMap = {}

            var check = () => {
                let pending = _.find(catMap, (o) => o === "pending")
                if (!pending)
                    resolve(catMap)
            }

            var getter = (cat, depth) => {
                console.log("Cat:", cat, depth)
                if (depth > 4)
                    return
                if (catMap.hasOwnProperty(cat))
                    return
                catMap[cat] = "pending"
                this.getSubCategories(cat)
                    .then((subCats) => {
                        catMap[cat] = "ok"
                        subCats.forEach((subCat) => {
                            console.log("Sub cat:", subCat, depth)
                            getter(subCat, depth + 1)
                        });
                        check()
                    })
                    .catch(function (error) {
                        catMap[cat] = "failed"
                        console.error("An error", error);
                    });
            }

            getter(baseCat, 1)
        })

    }

    getAllCategories(title) {

        var queueItem = (catMap, id, level, resolve, reject) => {
            if (level > 5)
                return false
            if (catMap.hasOwnProperty(id))
                return false
            catMap[id] = "pending"
            this.getCategories(id)
                .then((cats) => {
                    catMap[id] = "done"
                    console.log("catMap", catMap)
                    if (cats)
                        cats.map((v) => { return queueItem(catMap, v, level + 1, resolve, reject); })
                    let numToDo = _.reduce(catMap, (prev, value, key) => { return (value === "pending") ? prev + 1 : prev }, 0)
                    if (numToDo === 0)
                        resolve(catMap)
                })
                .catch(function (error) {
                    reject(error);
                });
            return true
        }

        var catMap = {}
        return new Promise((resolve, reject) => {
            queueItem(catMap, title, 0, resolve, reject)
            // this.getCategories(title)
            //     .then((cats) => {
            //         cats.map((v) => { catMap[v] = true })
            //         catMap[title] = cats
            //         cats.map((v) => {
            //             if (!catMap.hasOwnProperty(v)) {
            //                 this.getCategories(v).then((childCats) => {
            //                     catMap[v] = childCats
            //                 })
            //             }
            //         })
            //         let numToDo = map.keys().reduce((prev, key) => { return (map[key]) ? prev : prev + 1 }, 0)
            //         resolve(catMap)
            //     })
            //     .catch(function (error) {
            //         reject(error);
            //     });
            // }
        })
    }
}

var collectLinks = (all, title, elm) => {
    if (_.isArray(elm))
        _.forEach(elm, (val) => collectLinks(all, title, val))
    else if (_.isObject(elm)) {
        _.forEach(elm, (val, key) => {
            if (key === "title")
                title += "/" + val
            if (key === "links")
                _.forEach(elm["links"], (link) => all.push({ section: title, page: link.page }))
            else
                collectLinks(all, title, val)
        })
    }
    return all
}

var services = new WikiServices()
export default services