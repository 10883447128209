import axios from 'axios';


export class IFramelyService {

    async getEmbedInfo(url) {
        console.log("IFramelyService:", url)
        return axios.jsonp("https://iframe.ly/api/iframely", {
            // url: source,
            timeout: 1000,
            params: {
                url: "https://www.youtube.com/watch?v=-nu-1iIGaSQ&api_key=bd0b64d037fa00ada3609d",
                api_key: "bd0b64d037fa00ada3609d",
            }
        })
            .catch(function (error) {
                console.error("An error", error);
            });
    }

}

const iframlyService = new IFramelyService
export default iframlyService 