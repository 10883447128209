import React, { useContext, useState, useCallback, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from "lodash"

// import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
// import CardMedia from '@material-ui/core/CardMedia';
// import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
// import Collapse from '@material-ui/core/Collapse';
// import Avatar from '@material-ui/core/Avatar';
// import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
// import { blue, grey } from '@material-ui/core/colors';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import JamCtx from '../jam-app/JamCtx';
// import YouTube from 'react-youtube';

import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import LinkIcon from '@material-ui/icons/Link';
import EditIcon from '@material-ui/icons/Edit';
import { Content, Media } from "./ContentBlocks"
import CardActionArea from '@material-ui/core/CardActionArea/CardActionArea';

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import CardHelper from "./CardHelper"

const useStyles = makeStyles(theme => ({
  card: {
    // maxWidth: 345,
    marginBottom: "10px",
    userSelect: "none",
    "--swiper-navigation-size": "20px"
  },
  swiper: {
  },
  header: {
    // userSelect: false
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: theme.palette.primary[100],
  },
  actionButton: {
    color: theme.palette.grey[100]
  }

}));

export default function CarouselSwiperCard(props) {
  const jamCtx = useContext(JamCtx);
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [slideIndex, setSlideIndex] = React.useState(0);
  const [clickX, setClickX] = React.useState(0);
  const comp = this
  const data = props.data

  const initialContent = _.last(data.content)
  const initialSlideKey = initialContent ? initialContent.id : undefined

  // Swiper instance
  const [swiper, updateSwiper] = useState(null);
  // Slides current index
  var [currentIndex, updateCurrentIndex] = useState(0);
  // Params definition
  const currentContent = data.content[currentIndex]

  const updateIndex = useCallback(() => updateCurrentIndex(swiper.realIndex), [
    swiper
  ]);

  const onTap = useCallback(() => console.log(swiper.realIndex), [
    swiper
  ]);

  // Add eventlisteners for swiper after initializing
  useEffect(() => {
    if (swiper !== null) {
      swiper.on("slideChange", updateIndex);
      // swiper.on("tap",onTap);
    }

    return () => {
      if (swiper !== null) {
        swiper.off("slideChange", updateIndex);
        // swiper.off("tap",onTap);
      }
    };
  }, [swiper, updateIndex]);

  const swiperParams = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 30,
    // pagination: {
    //   el: '.swiper-pagination',
    //   clickable: true,
    // },
    centeredSlides: true,
    getSwiper: s => {
      console.log("get swiper", s)
      updateSwiper(s)
    }
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleMouseDown = e => {
    // store mouse position when click starts
    setClickX(e.screenX);
  }

  const handleClick = e => {
    const delta = Math.abs(e.screenX - clickX);

    if (delta > 10) {
      // If mouse moved more than threshold, ignore the click event
      e.preventDefault();
    }
    else {
      console.log("clicked")
      let pivotId = _.nth(data.content, slideIndex).id
      jamCtx.do({ type: "setPivot", pivotId })
    }
    setClickX(0);
  }

  let cardIds = {}
  let cardComp = (
    <Card key={data.id} className={"CarouselCard " + classes.card} >
      <CardHeader
        // disableTypography={true}
        className={classes.header}
        // onClick={() => jamCtx.do({ type: "setPivot", pivotId: data.id })}
        // action={
        //   <Fragment>
        //     {props.chooseTarget ?
        //       <IconButton aria-label="settings" onClick={() => jamCtx.do({ type: "returnTarget", target: data.id })}>
        //         <LinkIcon />
        //       </IconButton>
        //       :
        //       <IconButton aria-label="settings" onClick={() => jamCtx.do({ type: "edit", id: data.id })}>
        //         <EditIcon className={classes.actionButton} />
        //       </IconButton>
        //     }
        //   </Fragment>
        // }
        title={<div onClick={() => alert("bam")}>{currentContent ? data.title : null}</div>}
        titleTypographyProps={{ variant: "body1" }}
        subheader={currentContent ? currentContent.title : data.title}
        subheaderTypographyProps={{ variant: "h5" }}
      />
      <div>
        <Swiper {...swiperParams}
          activeSlideKey={currentContent ? currentContent.id : undefined}>
          {_.map(data.content, (itemData) => {
            let helper = CardHelper(jamCtx, itemData)
            cardIds[itemData.id + ""] = (cardIds[itemData.id + ""] || 0) + 1
            return (
              <div key={itemData.id}
                onClick={helper.getHandler("titleClick")}
              >
                <Media data={itemData} helper={helper}/>
                <Content data={itemData} helper={helper}/>

              </div>
            )
          }
          )}
        </Swiper>
      </div>
      <CardActions>
        {_.map(data.actions, (action, i) =>
          <Button key={i} size="small" color="primary">
            {action.text}
          </Button>
        )}
      </CardActions>
    </Card>
  );

  console.log("CarouselSwiperCard: card ids", cardIds)

  return cardComp

}
