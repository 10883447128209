import _ from "lodash"
import uuid from 'uuid/v4'

import { filter, objHasText, stringify } from "../utils/StringUtils"

import GraphLibUtils from "./GraphLibUtils"
import RDF from "./SimpleRdfUtils"

export default class JamModel {

    constructor(notes) {
        this.state = { notes }
    }

    // get topics() { return this.state.topics }
    get notes() { return this.state.notes }
    get graph() {
        let state = this.state
        if (!state.graph)
            state.graph = GraphLibUtils.makeGraph(state.notes)

        return state.graph
    }
    get rdf() {
        let state = this.state
        if (!state.rdf)
            state.rdf = RDF.makeRDF(state.notes)

        return state.rdf
    }

    getNoteEditCtx(noteId) {
        let note = this.notes[noteId]
        let noteStr = stringify(note)

        return { noteId: note.id, noteStr }
    }

    getEmptyNote() {
        let note = {
            id: uuid(),
            store: "firebase",
            type: "",
            by: "danny@vatnik.com",
            title: "Love Daddy",
        }
        return note
    }

    getEmptyNotebook() {
        let id  = uuid()
        let note = {
            id,
            type: "Notebook",
            notebook: id,
            store: "firebase",
            by: "danny@vatnik.com",
            title: "New Notebook " + id,
        }
        return note
    }

    getLinks(sub, pred) {
        let topic = this.getTopic(sub)
        if (!topic) return []
        let links = _.filter(topic.getLinks(), link => link.pred == pred)

        return links
    }

    addLink(note, targetId) {
        let state = this.state
        let newNote = Object.assign({ links: [] }, note)
        newNote.links.push({ sub: note.id, pred: "link", obj: targetId })
        return newNote
    }

    // getThingNotebook(url) {
    //     return this.state.notes[url]
    // }

    // getCards(ctx,query) {
    //     var model = this

    //     let layout = new JamLayout(this, query)
    //     let cards = []
    //     switch (query.layout) {

    //         case "distance":
    //             cards = this.getPivotCards(ctx,layout)
    //             break
    //         case "notebook-selector":
    //             cards = this.getNotebookSelector(ctx,layout)
    //             break
    //         // case "outline":
    //         //     cards = this.getCardsOutline(layout)
    //         //     break
    //         default:
    //     }

    //     if (query.filterText)
    //         cards = _.filter(cards, card => objHasText(card,query.filterText))

    //     return cards
    // }

    // getNotebookSelector(ctx,layout) {
    //     let state = this.state
    //     let rdf = layout.model.rdf

    //     let notebooks = RDF.match(rdf, null, "@type", "sj/type#Notebook")
    //     _.forEach(notebooks, q => layout.addNoteCards(q.sub, "title"))

    //     return layout.cards
    // }

    // getPivotCards(ctx,layout) {
    //     let { pivot: pivotId, filterText } = layout
    //     let state = this.state
    //     let ids = GraphLibUtils.orderByPivot(this.graph, pivotId)
    //     let notes = _.map(ids, id => state.notes[id])
    //     let cards = _.mapValues(notes, note => layout.addNoteCards(note, "self"))

    //     return layout.cards
    // }

    // getPivotCardsSimple(layout) {
    //     let { pivot: pivotId, filterText } = layout
    //     let state = this.state

    //     let pivot = state.topics[pivotId]
    //     let kidTopics = this.getTopicKids(pivot)
    //     let topics = [pivot, ...kidTopics]
    //     let cards = _.mapValues(topics, topic => topic.getCards(this, {}))
    //     let layout = _.concat([], ...(_.values(cards)))

    //     return layout
    // }

    // newTopic(type) {
    //     let state = this.state

    //     let topic = topicFactory.create({ type })
    //     let note = topic.getEmptyNote({
    //         actor: "danny@vatnik.com"
    //     })

    //     return note
    // }

    // getTopicKids(topic) {
    //     let state = this.state
    //     let links = topic.getActiveLinks(this)
    //     return _.compact(_.map(links, link => state.topics[link.obj]))
    // }
}
