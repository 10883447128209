export default {
    header: {
        "id": "3ccafb5d-fd27-4c9b-9862-derft",
        "updated": {
            "seconds": 1589471593,
            "nanoseconds": 768000000
        },
        "title": "משגב - מטופלת",
        "direction": "rtl",
        "type": "Notebook",
        "notebook": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
    },
    // banner: "https://wellisco.com/wp-content/uploads/2020/08/eshel-he-top.png",
    banner: "https://wellisco.com/wp-content/uploads/2020/08/maccabi-app-header.png",
    style: {
        backgroundColor: "white",
        color: "white",
    },
}