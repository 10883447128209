import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from "lodash"

import JamCtx from "../JamCtx"
import Box from '@material-ui/core/Box';
import Scrollbar from 'react-smooth-scrollbar';
import { relativeTimeRounding } from 'moment';

const useStyles = makeStyles(theme => ({
    box: {
        // maxWidth: 345,
        // height: "100%",
        width: "100%",
        // transform: "scale(0.7)"
    },
    card: {
        border: "1pt solid #CCC6",
        backgroundColor: "#fdfdfd",
    }
    // wrapper: {
    //     height: "100%",
    //     // minHeight: 900,
    //     position: "relative",
    // }
    // scroll: {
    //     flexGrow: 1
    // },
    // scrollDiv: {
    //     height: "100%",
    //     overflow: "scroll",
    //     overflowScrolling: "touch",
    // },
    // textField: {
    //     marginTop: "0px"
    // }
}));

export default function JamCardView(props) {
    const classes = useStyles();
    const jamCtx = useContext(JamCtx)
    let cardIds = {}
    let comp = (
        // <Scrollbar className={classes.scroll}>
        // <div className="scroll-div">
        <div m={0} key="main" {...props} className={classes.box}>
            {_.map(props.cards, card => {
                if (_.isUndefined(card.id))
                    console.warn("Undefined card id for", card)
                cardIds[card.id + ""] = (cardIds[card.id + ""] || 0) + 1
                let Card = jamCtx.cardFactory.create(card)
                return <Card key={card.id} data={card} className={classes.card} />
            })}
        </div>
        // </div>
        // </Scrollbar>
    );

    console.log("card ids", cardIds)

    return comp
}
