import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from "lodash"

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import JamCtx from '../jam-app/JamCtx';
import CardHelper from "./CardHelper"

import { Content, Media, Header } from "./ContentBlocks"

const useStyles = makeStyles(theme => ({
  card: {
    // maxWidth: 345,
    marginBottom: "10px",
    border: "none",
    backgroundColor: "#FEDDDD"
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  // actionIcon: {
  //   color: theme.palette.primary.light
  // },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: theme.palette.primary[100],
  },
  actionButton: {
    color: theme.palette.grey[100]
  }
}));

export default function AlertCard(props) {
  const jamCtx = useContext(JamCtx);
  const classes = useStyles();
  const card = props.data
  const data = card

  let helper = CardHelper(jamCtx, card)

  return (
    <Card key={card.id} className={["SimpleCard", classes.card, data.direction == "rtl" ? "rtl" : ""].join(" ")}
      onClick={helper.getHandler("titleClick")}
      raised={false}
    >
      <Header key="headre" data={data} helper={helper} />

      <Media key="media" data={data} helper={helper} />
      <Content key="content" data={data} helper={helper} />

    </Card>
  );
}