import _ from "lodash"
import { stringify } from "../utils/StringUtils"
import RDF from "./SimpleRdfUtils"
import JamModel from "./JamModel";
import ModelExplorer from "./ModelExplorer"

export default class JamNotebookModel extends JamModel {

    static getNotebookModel(orgModel, baseNotebookId) {
        // let orgModel = this
        let rdf = orgModel.rdf
        var layerIds = []
        let model = {}
        let idMap = {}

        let assert = cond => {
            if (!cond) {
                // console.assert(cond)
                debugger
            }
        }

        let importNotebooks = nbId => {
            let imports = RDF.match(rdf, nbId, "@import")
            _.forEach(imports, q => importNotebookNotes(q.o))
        }

        let importNotebookNotes = refNotebookId => {
            let refNotes = RDF.match(rdf, null, "notebook", refNotebookId)
            _.forEach(refNotes, q => {
                model[q.s] = q.sub
                idMap[q.s] = q.s
            })
        }

        let collectLayerIds = nbId => {
            let bases = RDF.match(rdf, nbId, "@base")
            _.forEach(bases, base => collectLayerIds(base.o))
            layerIds.push(nbId)
        }

        let updateModel = layer => {
            let layerNotes = RDF.match(rdf, null, "notebook", layer)
            _.forEach(layerNotes, q => updateNote(q.sub))
        }

        let updateNote = note => {
            let bases = RDF.match(rdf, note.id, "@base")
            let base = _.first(bases)

            idMap[note.id] = note.id
            if (base) {
                let baseNote = model[base.o]
                assert(!_.isEmpty(baseNote))

                let mergedNote = mergeNotes(note, baseNote)

                let prototypes = _.concat([baseNote.id], baseNote["@prototypes"] || [])
                let newNote = _.assign({}, mergedNote, { "@prototypes": prototypes })

                idMap[baseNote.id] = note.id
                delete model[baseNote.id]
                model[note.id] = newNote
            }
            else {
                model[note.id] = _.clone(note)
            }
        }

        let mergeNotes = (note, baseNote) => {
            let links = _.concat(note.links || [], baseNote.links || [])
            links = _.filter(links, link => link.pred !== "@base")

            let newNote = _.merge({}, baseNote, note, { links })

            return newNote
        }

        let resolveLinks = links => {
            let newLinks = _.map(links, link => {
                let newLink = _.clone(link)
                if (link.obj)
                    newLink.obj = resolveId(link.obj)
                if (link.sub)
                    newLink.sub = resolveId(link.sub)
                return newLink
            })
            return newLinks
        }

        let unresolvedIds = []
        let resolveId = id => {
            // assert(idMap[id])
            let curId = id
            while (idMap[curId] != curId) {
                let mappedId = idMap[curId]
                if (!mappedId)
                    unresolvedIds.push(curId)
                curId = mappedId
            }
            return curId
        }
        // Do it!

        importNotebooks(baseNotebookId)

        collectLayerIds(baseNotebookId)

        _.forEach(layerIds, layer => updateModel(layer))

        let finalNotes = _.mapValues(model, note => _.assign({}, note, { links: resolveLinks(note.links) }))

        if (!_.isEmpty(unresolvedIds))
            console.log("Unresolved ids for notebook", baseNotebookId, unresolvedIds)

        return new JamNotebookModel(orgModel, baseNotebookId, finalNotes, idMap)
    }

    constructor(orgModel, notebookId, notes, idMap) {
        super(notes)
        this.orgModel = orgModel
        this.notebookId = notebookId
        this.idMap = idMap
    }

    explore(basId) {
        return new ModelExplorer(this, basId)
    }

    getNoteEditCtx(noteId) {
        let flatNote = stringify(this.notes[noteId])
        let note = this.orgModel.notes[noteId]
        let noteStr = stringify(note)

        return { noteId: note.id, noteStr, flatNote }
    }

    getUserOverridingNote(userId) {
    }
    
    getOverridingNote(baseId) {
        let idMap = this.idMap
        let unresolvedIds = []
        let resolveId = id => {
            // assert(idMap[id])
            let curId = id
            while (idMap[curId] != curId) {
                let mappedId = idMap[curId]
                if (!mappedId)
                    unresolvedIds.push(curId)
                curId = mappedId
            }
            return curId
        }

        if (!baseId) {
            console.log("Bad baseId:", baseId)
            return undefined
        }

        let baseNote = this.notes[baseId]
        if (!baseNote) {
            console.log("Failed to find baseId:", baseId)
            return undefined
        }

        let resolvedBaseId = resolveId(baseId)
        if (!resolvedBaseId) {
            console.log("Failed to resolve baseId:", baseId)
            return undefined
        }

        let resolvedNote = this.orgModel.notes[resolvedBaseId]
        if (resolvedNote && resolvedNote.notebook === this.notebookId)
            return resolvedNote

        return undefined
    }
}
