import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from "lodash"
import Box from '@material-ui/core/Box';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ArrowBack from '@material-ui/icons/ArrowBack';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ViewStreamIcon from '@material-ui/icons/ViewStream';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import JamCtx from "../jam-app/JamCtx"
import TextField from '@material-ui/core/TextField/TextField';

const useStyles = makeStyles(theme => ({
    box: {
        // maxWidth: 345,
        // height: "40px",
        // width: "100%",
        // backgroundColor: "black",
        flexShrink: 0,
        flexGrow: 0,
        textAlign: "center",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyItems: "center",
        justifyContent: "space-around",
        margin: 5,
        marginLeft: 10,
        marginRight: 10,
    },
    textField: {
        [`& fieldset`]: {
            borderRadius: 20,
        },
    }
}));

export default function Footer() {
    const jamCtx = useContext(JamCtx)
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleChange = (e, v) => {
        switch (v) {
            case "back":
                jamCtx.do({ type: "back" })
                break
            case "new":
                jamCtx.do({ type: "new" })
                break
            case "about":
                jamCtx.do({ type: "sendToParent", event: "about" })
                break
            case "settings":
                jamCtx.do({ type: "sendToParent", event: "settings" })
                break
            case "sign-out":
                jamCtx.do({ type: "sendToParent", event: "sign-out" })
                break
            case "notebook":
                jamCtx.do({ type: "setPanel", panel: "notebook" })
                break
            case "card-list":
                jamCtx.do({ type: "setPanel", panel: "card-list" })
                break
            case "notebook-selector":
                jamCtx.do({ type: "setPanel", panel: "notebook-selector" })
                break
            case "demo":
                jamCtx.do({ type: "setPanel", panel: "demo", panelState: { demoId: undefined } })
                break
            default:
                jamCtx.do({ type: "setPanel", panel: v })
                break
        }

    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuSelect = (value) => {
        handleClose()
        handleChange(null, value)
    }

    const items = {
        "about": "About",
        "settings": "Settings",
        "sign-out": "Sign Out",
        "notebook-selector": "Notebook Selector",
        "notebook": "Notebook Cards",
        "card-list": "All Cards",
        "search": "Search",
        "terminal": "Terminal",
        "export": "Export",
        "demo": "Demo Cards",
        // "graph": "Graph",
    }

    return (
        <Box m={1} className={classes.box}>
            <ArrowBack color="primary"
                onClick={() => jamCtx.do({ type: "back" })}
            />
            {/* <AddCircleOutlineIcon color="primary"
                onClick={() => jamCtx.do({ type: "new" })}
            /> */}
            {/* <MenuBookIcon color="primary"
                onClick={() => jamCtx.do({ type: "setPanel", panel: "notebook" })}
            /> */}
            <ViewStreamIcon color="primary"
                onClick={() => jamCtx.do({ type: "setPanel", panel: "card-list" })}
            />
            <LocalLibraryIcon color="primary"
                onClick={() => jamCtx.do({ type: "setPanel", panel: "notebook-selector" })}
            />
            <MoreVertIcon color="primary"
                onClick={handleClick}
            />

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {_.map(items, (text, key) =>
                    <MenuItem key={key} onClick={() => handleMenuSelect(key)}>{text}</MenuItem>
                )}
            </Menu>

            <TextField size="small" variant="outlined" className={classes.textField}
                value={jamCtx.mainText}
                onChange={e => jamCtx.do({ type: "setMainText", text: e.target.value })}
            />

        </Box>
        // <BottomNavigation value={jamCtx.panel} onChange={handleChange} className={classes.root}>
        //     <BottomNavigationAction label="Back" value="back" icon={<ArrowBack />} />
        //     <BottomNavigationAction label="New" value="new" icon={<AddCircleOutlineIcon />} />
        //     <BottomNavigationAction label="Notebook" value="notebook" icon={<MenuBookIcon />} />
        //     <BottomNavigationAction label="Card list" value="card-list" icon={<ViewStreamIcon />} />
        // </BottomNavigation>
    );
}