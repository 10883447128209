import React, { useContext } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import _ from "lodash"
import JamCtx from '../jam-app/JamCtx';
// import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// import CardContent from '@material-ui/core/CardContent';
import { Content, Media, Actions } from "./ContentBlocks"
import CardHelper from './CardHelper';
import Avatar from '@material-ui/core/Avatar/Avatar';

export default function MessageCard(props) {
    const jamCtx = useContext(JamCtx);
    const card = props.data
    const data = card

    // let titleAction = _.get(data, "actions.titleClick")
    let noteCssClass = "speech-bubble " + (data.snapToBottom ? "bottom" : data.noteBy)
    if (data.direction == "rtl") noteCssClass += " rtl"
    let helper = CardHelper(jamCtx, card)

    let bubble = (<div key="bubble" className={noteCssClass}>
        {(data.title || data.subTitle) &&
            <div>
                {data.title && <Typography variant="h6">{data.title}</Typography>}
                {data.subTitle && <Typography variant="body1">{data.subTitle}</Typography>}
            </div>
        }
        <Media key="media" helper={helper} data={data} />
        <Content key="content" helper={helper} data={data} />
    </div>)

    let avatar = (<Avatar alt="Avatar" src={null}>
        DV
        </Avatar>)

    //Choose mode
    let comps = []
    if (data.snapToBottom) {
        comps = [bubble]
    }
    else {
        // comps = data.noteBy === "otherindex.css" ? [avatar, bubble] : [bubble, avatar]
        comps = [bubble]
    }

    return (
        <div m={0} className={"NoteCard "}
            onClick={helper.getHandler("titleClick")}
        >
            {comps}
        </div>
    );
}