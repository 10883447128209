import SimpleCard from "./SimpleCard"
import QuestionCard from "./QuestionCard"
// import CarouselCard from "./CarouselCard"
import CarouselSwiperCard from "./CarouselSwiperCard"
import TitleCard from "./TitleCard"
import TileCard from "./TileCard"
import MessageCard from "./MessageCard"
import AppBarCard from "./AppBarCard"
import AlertCard from "./AlertCard"

const cards = { SimpleCard, CarouselCard: CarouselSwiperCard, title: TitleCard, MessageCard, QuestionCard, TileCard, AppBarCard, AlertCard }

export default class JamCardFactory {

    create(def) {
        let card = cards[def.type]
        if (!card)
            card = SimpleCard

        return card
    }

    get cards() { return cards }

    get cardTypes() { return Object.keys(cards) }
}