import _ from "lodash"
import uuid from 'uuid/v4'
import youTubeService from "../services/YouTubeServices"
import NotebookAgent from "./NotebookAgent";
import JamNotebookModel from "../jam-model/JamNotebookModel";
import NoteToCardHelper from "./helpers/NoteToCardHelper";
import { getLocalPart } from "../vcb";

export default class YouTubeAgent extends NotebookAgent {

    getCards(what, template) {
        let ctx = this.ctx
        let nbModel = JamNotebookModel.getNotebookModel(ctx.model, this.url)

        let youTubeFirst = nbModel.explore().first("content").note()
        let youTubeOthers = nbModel.explore().all("content").notes()

        switch (what) {
            case "all": {
                if (template == "carousel") {
                    let vidCarousel = {
                        id: uuid(),
                        content: this.layout.getNotesCards(youTubeOthers, "mediaOnly"),
                        type: "CarouselCard"
                    }
                    return [vidCarousel]
                }
                else
                    return this.layout.getNotesCards(youTubeOthers, template)
            }
            case "first":
                return [this.layout.getNoteCard(youTubeFirst, template)]
            default:
                return super.getCards(what, template)

        }
        console.error("Could not get for ", this.url, what, template)

        return []
    }

    async load() {
        let ctx = this.ctx
        let url = this.url
        let model = ctx.syncModel
        let current = model.notes[url]
        if (!current) {
            ctx.setNotes([{
                id: url,
                title: "Searching YouTube",
                body: url,
                type: "Notebook",
                notebook: url,
                loading: true,
            }], "local")

            let title = getLocalPart(url)
            let notes = await youTubeService.getSearchNotebook(url, title)
            ctx.setNotes(notes, "local")

            return
        }
    }
}