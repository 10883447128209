const inviteFriendMesg = encodeURIComponent("הי, ויאולט כהן מזמינה אותך להצטרף למכבי שירותי בריאות בתנאים מיוחדים! \n\nhttps://demo.wellisco.com/?nb=demo/maccabi/friend")

export default {
    mode: "notebook",
    header: {
        "id": "3ccafb5d-fd27-4c9b-9862-derft",
        "updated": {
            "seconds": 1589471593,
            "nanoseconds": 768000000
        },
        "title": "ויאולט - ראשי",
        "direction": "rtl",
        "type": "Notebook",
        "notebook": "00003297-b2fc-4515-9ae6-3b4d46a80c15"
    },
    banner: "https://wellisco.com/wp-content/uploads/2020/08/eshel-he-top.png",
    style: {
        backgroundColor: "#14548c",
        color: "white",
    },
    cards: [

        {
            "id": "550bafbb-71f9-442b-8ff5-0a98780d9895",
            "noteBy": "me",
            "body": "ויאולט כהן מזמינה אותך לתרגול שיווי משקל ומניעת נפילות",
            "updated": {
                "seconds": 1588268246,
                "nanoseconds": 508000000
            },
            "notebook": "079bf8cd-a387-4688-98c3-538126032e24",
            "type": "Video",
            "created": {
                "nanoseconds": 78000000,
                "seconds": 1586690971
            },
            "direction": "rtl",
            "videoId": "mlZbPYUzfUg",
            "bodyTitle": "קצת לזוז",
            // "select": {
            //     "options": [
            //         {
            //             "value": "good",
            //             "text": "היה יופי"
            //         },
            //         {
            //             "value": "mid",
            //             "text": "קצת קשה"
            //         },
            //     ],
            //     "caption": "איך היה?"
            // },
            // "actionButtons": [
            //     { "text": "היה כיף, שתפי עם חברים" }
            // ],

        },

    ]
}