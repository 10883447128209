import _ from "lodash"

export default function NoteHelper(note) {

    function getLinksByPred(pred) {
        if (!note) return []
        let links = _.filter(note.links,link => link.pred === pred)
        return links
    }

    return { getLinksByPred }
}

// export default new NoteToCardHelper()