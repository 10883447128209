import _ from "lodash"
// import wdk from "wikidata-sdk"
import axiosJsonpPro from 'axios-jsonp-pro';
import { getLocalPart } from "../vcb"
import { stringify } from "../utils/StringUtils";

var source = "https://www.wikidata.org/w/api.php"

export class WikiDataService {

    async getNotebook(url) {
        let wikipediaTitle = getLocalPart(url)

        let result = await this.getData(wikipediaTitle)

        if (!result)
            return {
                id: url,
                notebook: url,
                type: "Notebook",
                title: "WikiData - error",
            }

        let props = {
            "P672": "MeSH Code",
            "P486": "MeSH Descriptor",
            "P4896": "3D Model",
        }

        let claims = {}
        _.forEach(result.entities, (entity, key) => {
            claims = _.mapValues(props, (name, prop) => entity ?.claims ?.[prop] ?.[0] ?.mainsnak ?.datavalue ?.value)
        })
        claims = _.filter(claims)

        let root = {
            id: url,
            notebook: url,
            listeningForNotes: true,
            type: "Notebook",
            title: "WikiData " + wikipediaTitle,
            body: stringify(claims),
            links: _.map(claims, (data, prop) => ({ pred: "content", obj: url + "/" + prop })),
        }

        let values = _.map(claims, (value, prop) => ({
            id: url + "/" + prop,
            notebook: url,
            title: prop,
            value,
            body: value,
        }))


        let notes = _.keyBy(_.concat(root, values), "id")
        console.log("claims", claims, notes)

        return notes
    }

    async getData(wikipediaTitle) {

        let url = "https://www.wikidata.org/w/api.php?action=wbgetentities&titles=" + wikipediaTitle + "&sites=enwiki&format=json"

        return axiosJsonpPro.jsonp(url, { timeout: 5000 })
            .catch(function (error) { console.error("An error", error); })
    }

    // var source = "https://www.wikidata.org/w/api.php?action=wbsearchentities&language=en"

    wikiSearch(searchTerm) {
        console.log("Term:", searchTerm)
        return axiosJsonpPro.jsonp(source, {
            // url: source,
            timeout: 1000,
            params: {
                action: "wbsearchentities",
                language: "en",
                format: "json",
                props: "info|descriptions",
                limit: 50,
                // prop: "info",
                // inprop: "url",
                search: searchTerm,
            }
        })
            .catch(function (error) {
                console.error("An error", error);
            });
    }

}

var service = new WikiDataService()
export default service