import React, { Component, Suspense } from 'react';
import _ from "lodash"

import JamCtx from '../jam-app/JamCtx';
import JamAppHistory from "./JamAppHistoryV3"

import JamActionQueue from '../jam-app/JamActionQueue';

import JamModel from '../jam-model/JamModel';
import { stringify } from "../utils/StringUtils"

import JamCardFactory from '../jam-card-ui/JamCardFactory';

import Storage from "../storage/Storage"
import AgentFactory from "../agents/helpers/AgentFactory"

const localStorageFields = {
    navStack: true,
    // panel: true
}

class JamApp extends Component {

    constructor(props) {
        super(props)

        // this.storage = Storage()
        this.history = JamAppHistory(this)

        this.actionQueue = props.actionQueue
        // this.actionQueueSubscription = this.actionQueue.addListener((action) => this.processAction(action))

        this.cardFactory = new JamCardFactory()
        this.agentFactory = new AgentFactory()

        let startModel = new JamModel({})
        this.syncModel = startModel
        this.state = {
            do: (action) => {
                if (!action) return
                console.log("Sending", action)
                this.actionQueue.push(action)
            },
            importNotebook: this.importNotebook.bind(this),
            setNotes: this.setNotes.bind(this),
            cardFactory: this.cardFactory,
            model: startModel,
            // storage: this.storage,
            // navStack: this.history.getInitialNavStack(),
            curNavFrame: this.history.getInitialNavStack()[0],
            history: this.history,
        }

        _.forEach(localStorageFields, (value, field) => {
            let str = localStorage.getItem(field)
            if (str) {
                try {
                    let obj = JSON.parse(str)
                    this.state[field] = obj
                }
                catch (e) { }
            }
        })
    }

    componentDidMount() {
        var comp = this

        this.actionQueueSubscription = this.actionQueue.addListener((action) => this.processAction(action))



        // if (this.state.storage)
        //     this.listenToStorage(this.state.storage)
    }

    listenToStorage(storage) {
        if (!storage) return

        var comp = this
        storage.listen((notes) => {
            console.log("Model got note", notes)
            let model = new JamModel(notes)
            this.syncModel = model
            comp.setState({ model })
        })
    }

    setState(state) {
        super.setState(state)

        _.forEach(localStorageFields, (value, field) => {
            let obj = state[field]
            if (obj) {
                try {
                    let str = JSON.stringify(obj)
                    localStorage.setItem(field, str)
                }
                catch (e) {
                    console.error("Failed to store state", state)
                }
            }
        })

    }

    setNotes(notes, storageName) {
        if (this.state.storage)
            this.state.storage.setNotes(notes, storageName)
    }

    componentWillUnmount() {
        this.actionQueue.unsubscribe(this.actionQueueSubscription)
    }

    componentDidUpdate() {

    }

    processAction(action) {
        console.log("Processing " + action.type, action)
        this.processBasicAction(action)
        this.processEditNoteAction(action)
    }

    importNotebook(url) {
        if (!url) return undefined

        let ctx = _.assign({}, this.state, { syncModel: this.syncModel })

        let agent = this.agentFactory.getAgent(ctx, url)

        return agent
    }

    async processBasicAction(action) {
        let ctx = this.state
        let sendEventToParent = this.props.sendEventToParent || (e => { })
        let history = this.history
        switch (action.type) {
            case "navigate":
                window.location.href = action.path
                break
            case "sendToParent":
                sendEventToParent(action.event)
                break
            case "updateUser":
                this.setState({ user: action.user })
                ctx.do({ type: "resetStorage" })
                break
            case "resetStorage":
                let storage = Storage()
                let model = new JamModel({})
                this.syncModel = model
                this.listenToStorage(storage)
                this.setState({ storage, model })
                break

            case "alert":
                alert(stringify(action))
                break
            case "new":
                let note = ctx.model.getEmptyNote()
                this.setNotes([note])
                break
            case "newNotbook": {
                let note = ctx.model.getEmptyNotebook()
                this.setNotes([note])
                break
            }
            case "selectNotebook": {
                history.pushStateChange({ panelState: { notebook: action.id, pivotId: undefined }, panel: "notebook" })
                break
            }
            case "setPivot": {
                // this.setPanelState({ pivotId: action.pivotId })
                if (action.pivotId !== history.getPanelState()?.pivotId)
                    history.pushStateChange({ panelState: { pivotId: action.pivotId } })
                break
            }
            case "back":
                history.back(action.returnAction)
                break
            case "setPanel": {
                let nbPath = action.panelState
                history.pushStateChange({ panel: action.panel, panelState: action.panelState, pathParts: action.pathParts })
                break
            }
            case "setPanelState": {
                history.setPanelState(action.stateDelta)
                break
            }
            case "setMainText": {
                this.setState({ mainText: action.text })
                break
            }
            case "showThing": {
                history.pushStateChange({ panel: "notebook", panelState: { notebook: action.url } })
                break
            }
            case "loadNotebook": {
                if (ctx.storage) {
                    ctx.storage.mergeNotes([{
                        id: action.id,
                        notebook: action.id,
                        listeningForNotes: true,
                    }], "local")

                    ctx.storage.getNotebook(action.id)
                }
                break
            }

        }
    }

    processEditNoteAction(action) {
        let ctx = this.state
        let history = this.history

        switch (action.type) {
            case "edit": {
                let editCtx = ctx.model.getNoteEditCtx(action.id)
                history.pushStateChange({
                    panel: "edit-note", panelState: {
                        editNoteId: editCtx.noteId,
                        editNote: editCtx.noteStr
                    }
                })
                break
            }
            case "editCard": {
                history.pushStateChange({
                    panel: "edit-note", panelState: {
                        editNoteId: action.noteId,
                        editNote: action.noteStr,
                        flatNote: action.flatNote
                    }
                })
                break
            }
            case "setEditNote": {
                history.setPanelState({ editNote: action.note })
                break
            }
            case "deleteEditNote": {
                this.state.do({ type: "back" })
                if (ctx.storage)
                    ctx.storage.deleteNote(action.note, action.note.store)
                break
            }
            case "editNoteSave":
                this.state.do({ type: "back" })
                this.setNotes([action.note])
                break
            case "editNoteCancel":
                this.state.do({ type: "back" })
                break
            case "chooseTarget":
                history.pushStateChange({ panel: "card-list", panelState: { selectTarget: true } })
                break
            case "returnTarget":
                this.state.do({ type: "back", returnAction: { type: "setTarget", target: action.target } })
                break
            case "setTarget":
                let panelState = history.getPanelState()
                if (panelState.editNote.id != action.target) {
                    try {
                        let note = JSON.parse(panelState.editNote)
                        let newNote = Object.assign({ links: [] }, note)
                        newNote.links.push({ pred: "link", obj: action.target })
                        history.setPanelState({ chooseTarget: false, editNote: stringify(newNote) })
                    }
                    finally { }
                }
                break
        }
    }

    render() {
        var ctx = Object.assign({}, this.state)
        return (
            <JamCtx.Provider value={ctx}>
                {this.props.children}
            </JamCtx.Provider>
        )
    }
}

export default (JamApp)
