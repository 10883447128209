import _ from "lodash"
import uuid from 'uuid/v4'
import NotebookAgent from "./NotebookAgent";
import NoteToCardHelper from "./helpers/NoteToCardHelper";
import { vcb, getLocalPart, makeUrl } from "../vcb"
import { replaceAll } from "../utils/StringUtils";

export default class WikipediaAgent extends NotebookAgent {

    getCards(what, template) {
        let ctx = this.ctx
        let url = this.url
        let title = getLocalPart(url)
        let model = ctx.model
        let nb = model.notes[url]
        if (!nb) return []

        let cards = []
        let addNoteCards = (note, template) => cards.push(this.layout.getNoteCard(note, template))
        // let addCard = (card) => cards.push(card)
        let addCards = (cardOrCards) => {
            let cardsToAdd = _.isArray(cardOrCards) ? cardOrCards : [cardOrCards]
            _.forEach(cardsToAdd,card => cards.push(card))
        }

        let wtfUrl = makeUrl("wikiWtf", title)
        let wtfAgent = ctx.importNotebook(wtfUrl)

        let wikiDataUrl = makeUrl("wikiData", title)
        let wikiDataAgent = ctx.importNotebook(wikiDataUrl)

        // let dataAgents = [wikiDataAgent]
        let dataCards = []
        if (wikiDataAgent.isReady()) {
            let meshPropNote = wikiDataAgent.getPropNote(ctx,"P486")
            if (meshPropNote) {
                let meshDescriptorId = meshPropNote.value
                let meshUrl = makeUrl("mesh",meshDescriptorId)
                let meshAgent = ctx.importNotebook(meshUrl)
                // dataCards = _.concat(dataCards, meshAgent.getCards("root", "self"))
                console.log("meshUrl",meshUrl)
            }
            let media3d = wikiDataAgent.getPropNote(ctx,"P4896")
            if (media3d) {
                // let fileName = "https://upload.wikimedia.org/wikipedia/commons/e/ef/" + replaceAll(media3d.value," ","_")
                let fileName = "https://commons.wikimedia.org/wiki/File:" + replaceAll(media3d.value," ","_")
                let card = this.layout.getNoteCard({
                    id: url + "/3D",
                    notebook: url,
                    title: media3d.value,
                    body: media3d.value,
                    model3d: fileName
                }, "self")
                dataCards.push(card)
            }
        }

        let youTubeUrl = makeUrl("wikiYouTube", title)
        let youTubeAgent = ctx.importNotebook(youTubeUrl)

        if (!this.allAgentsReady(wtfAgent, youTubeAgent)) {
            addNoteCards(nb, "header")
            return cards
        }

        let vidCarousel = youTubeAgent.getCards("all", "carousel")
        let wtfFirst = wtfAgent.getCards("firstSection", "self")
        let wtfOthers = _.tail(wtfAgent.getCards("sections", "titleAndImage"))
        let wikiDataRoot = wikiDataAgent.getCards("root", "self")
        let youTubeFirst = youTubeAgent.getCards("first", "self")

        if (template == "feed") {
            addCards(vidCarousel)
            addCards(wtfFirst)
            addCards(dataCards)
            addCards(wtfOthers)
        }

        if (template == "ref") {
            addCards(vidCarousel)
            addCards(wtfFirst)
        }

        return cards
    }

    async load() {
        let ctx = this.ctx
        let url = this.url
        let model = ctx.syncModel
        let current = model.notes[url]

        let title = getLocalPart(url)

        let wtfUrl = makeUrl("wikiWtf", title)
        let wtfAgent = ctx.importNotebook(wtfUrl)

        let youTubeUrl = makeUrl("wikiYouTube", title)
        let youTubeAgent = ctx.importNotebook(youTubeUrl)

        if (!current) {
            ctx.setNotes([{
                id: url,
                title: "Loading ...",
                body: url,
                type: "Notebook",
                notebook: url,
                loading: true,
            }], "local")
            return
        }

        if (this.allAgentsReady(wtfAgent, youTubeAgent)) {
            ctx.setNotes([
                {
                    id: url,
                    title: title,
                    body: url,
                    notebook: url,
                    type: "Notebook",
                    listeningForNotes: true,
                    links: [
                        { pred: "@import", obj: wtfUrl },
                        { pred: "@import", obj: youTubeUrl },
                    ],
                }
            ], "local")
        }
    }
}
