import _ from "lodash"
import uuid from 'uuid/v4'

export default function MemStorage() {
    const storage = {}

    // storage.notes = localStorage.getItem("localNotes")
    storage.notes = {}
    storage.handlers = []

    storage.setNotes = (notes) => {

        let noteMap = _.keyBy(notes, "id")

        let newNotes = _.assign({}, storage.notes, noteMap)

        storage.notes = newNotes

        _.forEach(storage.handlers, handler => handler(storage.notes))
    }

    storage.mergeNotes = (notes) => {

        let noteMap = _.keyBy(notes, "id")

        let newNotes = _.merge({}, storage.notes, noteMap)

        storage.notes = newNotes

        _.forEach(storage.handlers, handler => handler(storage.notes))
    }
    
    storage.listen = (notesHandler) => {
        storage.handlers.push(notesHandler)
        setTimeout(() => {
            notesHandler(storage.notes)
        }, 10);
    }

    return storage
}