import React, { useContext, useRef, useEffect, Fragment } from 'react';

export default class TouchInputBlock extends React.Component {
    render() {
      return (<div>Love</div>
        // <ReactTouchPosition className="example">
        //   <TouchPositionLabel />
        // </ReactTouchPosition>
      );
    }
  }
  
//   const TouchPositionLabel = (props) => {
//     const {
//       touchPosition: {
//         x = 0,
//         y = 0,
//       } = {},
//       isPositionOutside = true,
//       isActive = false
//     } = props;
  
//     return (
//       <div className="example__label">
//         {`x: ${x}`}<br />
//         {`y: ${y}`}<br />
//         {`isActive: ${isActive}`}<br />
//         {`isPositionOutside: ${isPositionOutside ? 'true' : 'false'}`}<br />
//       </div>
//     );
//   };
  