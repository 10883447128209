import _ from "lodash"
// import search from "youtube-search-promise"
import goggleKey from "./googleKey"

import ServiceBase from "./ServiceBase"
import uuid from 'uuid/v4'

var opts = {
    maxResults: 10,
    relevanceLanguage: "en",
    key: goggleKey,
    type: "video",
    videoEmbeddable: true,
};

export class YouTubeServices extends ServiceBase {

    do(ctx, params) {
        this.searchJams(params.term).then(notebook => {
            ctx.track.setNotebook(notebook)
        })
    }

    // me = {
    //     id: "tmSzRx9RYLk",
    //     link: "https://www.youtube.com/watch?v=tmSzRx9RYLk",
    //     kind: "youtube#video",
    //     publishedAt: "2016-11-16T21:22:35.000Z",
    //     channelId: "UCWa-psHkYjeeO5AbqY1kUaw",
    //     channelTitle: "lIBioHazardIl",
    //     title: "Frank Sinatra - L.O.V.E. (lyrics)",
    //     description: "L is for the way you look at me O is for the only one I see V is very, very extraordinary E is even more than anyone that you adore can Love is all that I can give to ...",
    //     thumbnails: {
    //         default: { url: "https://i.ytimg.com/vi/tmSzRx9RYLk/default.jpg", width: 120, height: 90 },
    //         medium: { url: "https://i.ytimg.com/vi/tmSzRx9RYLk/mqdefault.jpg", width: 320, height: 180 },
    //         high: { url: "https://i.ytimg.com/vi/tmSzRx9RYLk/hqdefault.jpg", width: 480, height: 360 },
    //     }
    // }

    async getSearchNotebook(url, query) {
        let results = await this.search(query)
        console.log("YouTube results", results)

        let resultNotes = _.map(results, (result) => ({
            id: "youtube_" + result.id,
            type: "Section",
            url: "https://semjam.com/youtube/" + result.id,
            title: result.title,
            imageUrl: result.thumbnails.default.url,
            videoId: result.id,
            notebook: url,
        }))

        let mainNote = {
            id: uuid(),
            title: "YouTube - " + query + " (main)",
            links: _.map(resultNotes, note => ({ pred: "content", obj: note.id })),
            notebook: url,
        }

        let notes = [
            {
                id: url,
                title: "YouTube - " + query,
                type: "Notebook",
                notebook: url,
                // links: [{ pred: "main", obj: mainNote.id }]
                links: _.map(resultNotes, note => ({ pred: "content", obj: note.id })),
                listeningForNotes: true,
            },
            // mainNote,
            ...resultNotes
        ]

        console.log("YouTube notes", notes)

        return notes
    }

    async search(searchTerm) {
        let search = await import("youtube-search-promise").then(module => module.default)
        return search(searchTerm, opts)
    }

    getRelated(id) {

    }

}

var service = new YouTubeServices()
export default service